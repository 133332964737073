import * as api from "./api";
import { setWexTokenAccessor } from "@wexinc/wex-common-web";

const pdfDashboard = "/dashboard-pdf";

let wexToken: string;

const usePDFService = () => {
  const isPdfView = window.location.pathname === pdfDashboard;
  if (isPdfView && !wexToken) {
    // in the sla-dashboard-pdf service, the token is taken from the auth header
    // and put into local storage before page load
    wexToken = localStorage.getItem("pdf-jwt") || "";
    setWexTokenAccessor(() => Promise.resolve(wexToken));
  }

  async function getCurrentDashboardAsPDF(): Promise<Blob> {
    const url = `${window.location.origin}${pdfDashboard}${window.location.search}`;
    const response = await api.getPDF(url);
    return response.blob();
  }

  return { getCurrentDashboardAsPDF, isPdfView };
};

export default usePDFService;
