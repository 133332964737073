import { OrganizationPicker } from "@wexinc/wex-common-web";
import { OrganizationInfo } from "services/organization/models";
import {
  Button,
  ConfirmModal,
  Heading,
  Label,
  LoadableContentContainer,
  PlusIcon,
  ScrollableContainer,
  XmarkIcon,
} from "@wexinc/wex-ui";
import { produce } from "immer";
import { useEffect, useState } from "react";
import useDashboardService, { Dashboard } from "services/dashboard";
import useSLAOrganizationService from "services/organization";

interface Props {
  dashboard: Dashboard;
  onClose: () => void;
}

const ShareDashboardModal: React.FC<Props> = (props) => {
  const dashboardService = useDashboardService();
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>("Loading...");
  const [organizations, setOrganizations] = useState<OrganizationInfo[]>([]);
  const { onClose, dashboard } = props;
  const slaOrgService = useSLAOrganizationService();

  async function onSave() {
    setLoadingMessage("Saving...");
    await dashboardService.setSharedOrganizationsForDashboard(
      dashboard.id,
      organizations.map((i) => i.id),
    );
    onClose();
  }

  useEffect(() => {
    async function onLoad() {
      const sharedOrgs = await dashboardService.getSharedOrganizationsForDashboard(dashboard.id);
      setOrganizations(sharedOrgs);
      setLoadingMessage(undefined);
    }
    onLoad();
    // eslint-disable-next-line
  }, [dashboard]);

  function addStagedOrg(organization: OrganizationInfo) {
    setOrganizations(
      produce((draft) => {
        draft?.push(organization);
      }),
    );
  }

  function removeStagedOrg(organization: OrganizationInfo) {
    setOrganizations(
      produce((draft) => {
        const index = draft?.findIndex((i) => i.id === organization.id);
        draft?.splice(index, 1);
      }),
    );
  }

  return (
    <ConfirmModal
      onOk={onSave}
      onCancel={onClose}
      okButtonText="Share"
      modalClassNames="w-[420px]"
      contentClassNames="max-h-72 min-h-[130px]"
      explicitOpenClose={true}
    >
      <LoadableContentContainer
        showLoading={!!loadingMessage}
        message={loadingMessage}
      />

      <Heading size="small">Share dashboard</Heading>
      <OrganizationPicker
        organizationService={slaOrgService}
        onOrganizationSelected={(org, hidePicker) => {
          addStagedOrg(org);
          hidePicker();
        }}
        getPickerOpenerContent={(showPicker: () => void) => (
          <Button
            buttonType="secondary"
            testId="select-organization"
            onClick={showPicker}
            className="absolute right-6 top-[27px]"
          >
            <PlusIcon className="mr-2" />
            Add Organization
          </Button>
        )}
        showModalOverlay={false}
        organizationIdsToExcludeFromView={[...organizations.map((i) => i.id), dashboard.organization.id]}
      />

      {organizations && organizations.length > 0 && (
        <ScrollableContainer className="my-4 max-h-52 w-[350px]">
          {organizations.map((i) => (
            <div
              className="flex items-center"
              key={i.name}
            >
              <Button
                allowFocusStyling={false}
                buttonType="secondary-critical"
                testId="remove-staged-member"
                size="small"
                onClick={() => removeStagedOrg(i)}
              >
                <XmarkIcon />
              </Button>
              <Label>{i.name}</Label>
            </div>
          ))}
        </ScrollableContainer>
      )}
      {organizations && organizations.length <= 0 && (
        <Label className="mt-12 block">Dashboard not shared with any Organizations.</Label>
      )}
    </ConfirmModal>
  );
};

export default ShareDashboardModal;
