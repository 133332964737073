import React from "react";
import { useImmerReducer } from "use-immer";
import { Dashboard, DashboardStore } from ".";

const initialState: DashboardStore = {
  currentDashboard: undefined,
};

interface DashboardContext {
  dashboardStore: DashboardStore;
  dispatch: (action: TAction) => DashboardStore;
}

const Context = React.createContext<DashboardContext>({
  dashboardStore: initialState,
  dispatch: () => initialState,
});

type TAction = { type: "set-current-dashboard"; payload: Dashboard };

// This state is protected by immer so mutate-away!!
function reducer(state: DashboardStore, action: TAction) {
  switch (action.type) {
    case "set-current-dashboard":
      state.currentDashboard = action.payload;
      break;
  }
  return state;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DashboardProvider(props: any) {
  const [dashboardStore, dispatch] = useImmerReducer(reducer, initialState);
  const value = React.useMemo(
    () => ({ dashboardStore, dispatch }),
    // eslint-disable-next-line
    [dashboardStore],
  );
  return (
    <Context.Provider
      value={value}
      {...props}
    />
  );
}

export { DashboardProvider, Context as DashboardContext };
