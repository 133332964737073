import { usePortalAppService } from "@wexinc/wex-common-web";
import { Endpoints } from "services/api";

const useSLAPortalAppService = () => {
  const portalAppService = usePortalAppService(Endpoints.copApiRoot);

  return {
    getPortalApps: portalAppService.getPortalApps,
    getCopApp: portalAppService.getCopApp,
  };
};

export default useSLAPortalAppService;
