import { PerformanceWidgetProperties, WidgetData } from "services/widget";
import { getUTCShortMonthName, toUTCDateString } from "util/date-helpers";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { VictoryAreaProps } from "victory-area";
import { VictoryAxisCommonProps } from "victory-core";
import { WidgetContent } from "../WidgetContentFactory";
import resolveConfig from "tailwindcss/resolveConfig";
import sharedTailwindTheme from "../../../../../tailwind.config";
import { Label } from "@wexinc/wex-ui";
import classnames from "classnames";

const fullConfig = resolveConfig(sharedTailwindTheme);

const quarters = ["1st Quarter", "2nd Quarter", "3rd Quarter"];

export function getPerformanceWidgetContent(widgetData: WidgetData): WidgetContent {
  const properties = widgetData.widget.properties as PerformanceWidgetProperties;

  const avgResponseTime = Math.round(
    widgetData.data.reduce((prevVal, curVal) => prevVal + (curVal.primaryValue ?? 0), 0) / widgetData.data.length,
  );

  const chartData = widgetData.data
    .map((i) => {
      i.primaryValue = i.primaryValue || 0;
      return i;
    })
    .map((i) => {
      const startTime = new Date(i.startTime);
      return {
        x: startTime,
        y: i.primaryValue,
        label: `${i.primaryValue!.toFixed(2)} ${toUTCDateString(startTime)}`,
      };
    });

  let ticksPredicate: (i: Date) => boolean;
  let tickFormatter: (i: Date) => string;
  const middleOfMonth = 15;

  if (widgetData.data.length <= 31) {
    // Get 1 date per week
    ticksPredicate = (i) => i.getDay() === 1;
    tickFormatter = (i) => toUTCDateString(i);
  } else if (widgetData.data.length <= 180) {
    // Get 1st date and each 1st of the month
    ticksPredicate = (i) => (i === chartData[0].x && i.getDate() < middleOfMonth) || i.getDate() === 1;
    tickFormatter = (i) => getUTCShortMonthName(i);
  } else {
    // Get 1 date per quarter (skip 4th quarter)
    ticksPredicate = (i) => i.getMonth() <= 8 && (i.getMonth() + 1) % 3 === 0 && i.getDate() === 1;
    tickFormatter = (i) => {
      const month = i.getMonth() + 1;
      const quarter = month / 3;
      return quarters[quarter - 1];
    };
  }

  const dateTickValues = chartData.map((i) => i.x).filter(ticksPredicate);

  const targetResponseTime = properties.targetResponseTime!;

  const topYAxis = targetResponseTime * 2;

  return {
    headerContent: (
      <>
        <Label
          className="text-utility-success-60 block text-right"
          size="small"
        >
          Target: <span>{targetResponseTime}ms</span>
        </Label>
        <Label
          size="small"
          className={classnames(
            "block text-right",
            {
              "text-utility-critical-50": avgResponseTime > targetResponseTime,
            },
            {
              "!text-utility-success-60": targetResponseTime >= avgResponseTime,
            },
          )}
        >
          Avg: <span>{avgResponseTime}ms</span>
        </Label>
        <Label className="text-neutral-dark-80 block">1000ms equals 1 second</Label>
      </>
    ),
    bodyContent: (
      <>
        <div>
          <VictoryChart
            padding={{ left: 60, bottom: 30, top: 20, right: 10 }}
            height={200}
            width={1000}
            maxDomain={{ y: topYAxis }}
            containerComponent={<VictoryVoronoiContainer voronoiDimension="x" />}
          >
            <PerformanceGradientSvg />
            <VictoryArea
              style={areaStyle()}
              data={chartData}
              labelComponent={
                <VictoryTooltip
                  constrainToVisibleArea
                  flyoutStyle={{
                    stroke: fullConfig.theme.colors.utility.black,
                    fill: fullConfig.theme.colors.utility.black,
                  }}
                  pointerLength={(datum) => {
                    if (datum.datum._y > topYAxis) {
                      return 0;
                    } else {
                      return 10;
                    }
                  }}
                  labelComponent={
                    <VictoryLabel
                      style={{
                        fill: fullConfig.theme.colors.neutral.dark["10"],
                      }}
                      text={({ datum }) => {
                        const date = datum.x as Date;

                        return `${parseInt(datum.y)} ${getUTCShortMonthName(date)}-${date.getUTCDate()}`;
                      }}
                    />
                  }
                />
              }
            />
            <VictoryLine
              style={{
                data: {
                  stroke: fullConfig.theme.colors.utility.success["60"],
                  strokeWidth: 1.5,
                },
              }}
              data={[
                { x: chartData[0].x, y: targetResponseTime },
                { x: chartData[chartData.length - 1].x, y: targetResponseTime },
              ]}
            />
            <VictoryAxis
              scale="time"
              tickValues={dateTickValues}
              tickFormat={tickFormatter}
              style={axisStyle}
            />
            <VictoryAxis
              domain={[0, topYAxis]}
              scale="linear"
              dependentAxis
              style={axisStyle}
              tickValues={[0, Math.trunc(Math.round(topYAxis / 2)), Math.trunc(topYAxis)]}
              tickFormat={(milliseconds: string) => `${milliseconds}ms`}
            />
          </VictoryChart>
        </div>
      </>
    ),
  };
}

const axisStyle: VictoryAxisCommonProps["style"] = {
  tickLabels: {
    fontSize: "11px",
    color: fullConfig.theme.colors.neutral.dark["60"],
    fontFamily: "Inter, Helvetica Neue, sans-serif",
  },
  grid: {
    stroke: fullConfig.theme.colors.neutral.dark["20"],
    strokeWidth: 0.5,
  },
  axis: {
    stroke: fullConfig.theme.colors.neutral.dark["20"],
    strokeWidth: 0.5,
  },
};

function areaStyle(): VictoryAreaProps["style"] {
  return {
    data: {
      fill: `url(#performanceAreaGradient)`,
      stroke: fullConfig.theme.colors.primary["50"],
      strokeWidth: 1,
      fillOpacity: 0.4,
    },
  };
}
const PerformanceGradientSvg: React.FC = () => (
  <svg>
    <defs>
      <linearGradient
        id="performanceAreaGradient"
        x1="0%"
        y1="0%"
        x2="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stopColor={fullConfig.theme.colors.primary["20"]}
        />
        <stop
          offset="95%"
          stopColor={fullConfig.theme.colors.utility.white}
        />
      </linearGradient>
    </defs>
  </svg>
);
