import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useFormContext, useWatch } from "react-hook-form";
import { Dashboard } from "services/dashboard";
import { Metric, MetricType, MetricTypeKeyValues, SaveMetric } from "services/metric";
import * as yup from "yup";

import { FieldValidationMessages, FieldValidationsError } from "@wexinc/wex-common-web";
import {
  AddOrEditModal,
  Button,
  CheckboxField,
  CircleExclamationIcon,
  LoadableContentContainer,
  SelectField,
  TailSpinIcon,
  TextAreaField,
  TextField,
  UseFormReturn,
} from "@wexinc/wex-ui";
import Widget from "pages/Dashboard/components/Widget";
import { Tooltip } from "react-tooltip";
import useMetricService from "services/metric/useMetricService";
import { WidgetData } from "services/widget";
import useWidgetService from "services/widget/useWidgetService";

interface Props {
  parentDashboard: Dashboard;
  onAddedOrUpdated: (metric: Metric) => Promise<void>;
  onCancel: () => void;
  existingMetric?: Metric;
}

const schema = yup.object().shape({
  name: yup.string().trim().required("Metric Name is required.").max(255, "Metric name cannot exceed 255 characters"),
  description: yup.string().trim().nullable().max(1000, "Description cannot exceed 1000 characters"),
  dataId: yup
    .string()
    .trim()
    .matches(/^[^-\s:]+$/, "Data ID cannot contain hyphens, colons, or blank spaces")
    .required("Data ID is required."),
});

type MetricPreviewType = {
  currentType: MetricType;
  organizationId: string;
};
/** 
 * MetricPreview: Displays widget preview or error 
 * based on dataId and dataFilter validation.
 */
const MetricPreview = (props: MetricPreviewType) => {
  const { currentType, organizationId } = props;
  const widgetService = useWidgetService();
  const [showLoading, setShowLoading] = useState(false);
  const [widgetData, setWidgetData] = useState<WidgetData>();
  const [error, setError] = useState<string>("");
  const { control, trigger } = useFormContext();

  const watchSloId = useWatch({ name: "dataId", control});
  const watchDataFilter = useWatch({ name: "dataFilter", control});

  /** 
   * Handles click: Validates dataId, fetches widget 
   * preview, and manages loading/error states.
   */
  const handleOnClick = async () => {
    setShowLoading(true);
    setError("");
    setWidgetData(undefined);

    const isValid = await trigger("dataId");

    if (isValid) {
      try {
        const data = await widgetService.getWidgetPreview(
          organizationId,
          currentType,
          watchSloId,
          {
            startTime: new Date(new Date().setDate(new Date().getDate() - 90)),
            endTime: new Date(),
          },
          watchDataFilter,
        );
        setWidgetData(data);
      } catch (e) {
        if (e instanceof FieldValidationsError) {
          const message = Object.values(e.fieldValidationMessages);
          setError(message.join(" "));
        }
      }
    }
    setShowLoading(false);
  };
  /** 
   * Determines button text based on loading state 
   * and widget data presence.
   */
  function buttonText() {
    if (showLoading) {
      return "Loading...";
    }
    if (!showLoading && !widgetData) {
      return "Show Preview";
    }
    return "Update Preview";
  }

  return (
    <div className="p-1 relative mt-4">
      <div className="flex items-center mb-4">
        <Button
          testId="fetch-preview-data-btn"
          onClick={handleOnClick}
          disabled={!watchSloId || showLoading}
        >
          {buttonText()}
        </Button>
        {showLoading && <TailSpinIcon className="pl-2" />}
      </div>
      <div>
        {widgetData &&
          (widgetData.data.length ? (
            <Widget
              widgetData={widgetData}
              containerClassName="!mb-4"
            />
          ) : (
            <div>The parameters are valid, but no data found</div>
          ))}
        {error && <div className="text-center text-utility-critical-50">{error}</div>}
      </div>
    </div>
  );
};


const AddOrUpdateMetricModal: React.FC<Props> = (props) => {
  const metricService = useMetricService();
  const [customValidationMessages, setCustomValidationMessages] = useState<FieldValidationMessages>({});
  const [saving, setIsSaving] = useState<boolean>(false);
  const { parentDashboard, existingMetric, onAddedOrUpdated, ...rest } = props;
  const [currentType, setCurrentType] = useState<MetricType>(existingMetric?.type || MetricType.Availability);
  const useFormReturn = useRef<UseFormReturn>();
  const [contentForMetricType, setContentForMetricType] = useState<ContentForMetricType>();
  const methods = useForm();

  useEffect(() => {
    setContentForMetricType(getContentForMetricType(currentType, customValidationMessages));
  }, [currentType]);

  const defaultValues =
    existingMetric ||
    ({
      name: "",
      type: MetricType.Availability,
      dataId: "",
      privateToOrganization: true,
    } as SaveMetric);

  async function onSubmit(metric: SaveMetric) {
    setIsSaving(true);
    setCustomValidationMessages({});
    try {
      const addedOrUpdatedMetric = await metricService.saveMetric(parentDashboard.organization.id, metric);

      return onAddedOrUpdated(addedOrUpdatedMetric);
    } catch (e) {
      setIsSaving(false);

      if (e instanceof FieldValidationsError) {
        setCustomValidationMessages(e.fieldValidationMessages);

        if (e.fieldValidationMessages.dataId) {
          useFormReturn.current?.setError("dataId", { message: e.fieldValidationMessages.dataId });
        }
      }
    }
  }

  function getIdLabel() {
    return currentType === MetricType.Availability ? "SLO Id" : "Metric Id";
  }

  function onFormInit(useFormReturnIncoming: UseFormReturn) {
    useFormReturn.current = useFormReturnIncoming;
  }

  return (
    <FormProvider {...methods}>
      <AddOrEditModal
        modalClassNames="!max-w-2xl min-w-[550px] w-full"
        formProps={{
          onSubmit,
          options: {
            defaultValues,
            resolver: yupResolver(schema),
          },
          onInit: (useFormReturn) => {
            onFormInit(useFormReturn);
          },
        }}
        {...rest}
        titleOrHeader={existingMetric ? `Edit ${existingMetric.name}` : "Create Metric"}
      >
        <LoadableContentContainer showLoading={saving} />
        <TextField
          name="name"
          label="Name"
          showRequiredDot
        />
        <div className="flex">
          <SelectField
            label="Type"
            name="type"
            containerClassName="w-1/3 mr-1"
            options={[{ label: "", value: "" }, ...MetricTypeKeyValues]}
            readonly={!!existingMetric}
            onChange={(e) => setCurrentType(e.currentTarget.value as MetricType)}
          />
          <TextField
            name="dataId"
            label={getIdLabel()}
            containerClassName="w-2/3 ml-1"
            showRequiredDot
            readonly={!!existingMetric}
          />
        </div>
        <TextAreaField
          name="description"
          label="Description"
        />
        <CheckboxField
          name="privateToOrganization"
          label={`Make private for ${parentDashboard.organization.name}`}
          disabled={!!existingMetric}
        />
        {contentForMetricType?.dynamicContent}
        <MetricPreview
          currentType={currentType}
          organizationId={parentDashboard.organization.id}
        />
      </AddOrEditModal>
    </FormProvider>
  );
};

interface ContentForMetricType {
  schema: yup.AnyObjectSchema | null;
  dynamicContent: React.ReactElement | null;
}

const tooltipText = "How to Format";

const handleIconClick = () => {
  window.open("https://docs.datadoghq.com/metrics/advanced-filtering/", "_blank");
};

function getContentForMetricType(
  type: MetricType,
  customValidationMessages: FieldValidationMessages = {},
): ContentForMetricType {
  switch (type) {
    case MetricType.Performance:
    case MetricType.Count: {
      const schema = yup.object().shape({
        dataFilter: yup.string(),
      });
      const dynamicContent = (
        <div className="flex items-center">
          <Tooltip
            className="whitespace-nowrap"
            disableStyleInjection="core"
          />
          <div className="w-[90%]">
            <TextField
              name="dataFilter"
              label="Additional Parameters"
              containerClassName="mb-0"
              externallySuppliedValidationMessage={customValidationMessages["dataFilter"]}
            />
          </div>
          <div
            data-tip={tooltipText}
            className="mx-auto cursor-pointer"
          >
            <CircleExclamationIcon
              className="cursor-pointer"
              onClick={handleIconClick}
            />
          </div>
        </div>
      );
      return { schema, dynamicContent };
    }

    default:
      return { schema: null, dynamicContent: null };
  }
}

export default AddOrUpdateMetricModal;
