import classnames from "classnames";
import { AvailabilityWidgetProperties, WidgetData, WidgetDataItem } from "services/widget";
import { toUTCDateString } from "util/date-helpers";
import { WidgetContent } from "../WidgetContentFactory";
import { CircleCheckIcon, Heading, Label } from "@wexinc/wex-ui";

export default function getAvailabilityWidgetContent(widgetData: WidgetData): WidgetContent {
  const properties = widgetData.widget.properties as AvailabilityWidgetProperties;
  const moreThan90DataPoints = widgetData.data.length > 90;
  const tickWidth = moreThan90DataPoints ? 3 : 4;
  const horizontalSpacing = moreThan90DataPoints ? 4 : 6;
  const meetsTarget = widgetData.summary.totalPercentage! >= properties.targetPercentage!;

  return {
    headerContent: (
      <div className="flex items-baseline">
        <Label size="small">{`Target: ${properties.targetPercentage}%`}</Label>
        <Heading
          size="small"
          className={classnames(
            meetsTarget ? "text-utility-success-50" : "text-utility-critical-50",
            "ml-1.5  font-semibold",
          )}
        >{`${widgetData.summary.totalPercentage}%`}</Heading>
      </div>
    ),
    bodyContent: (
      <>
        <div className="flex flex-wrap pt-5 items-baseline justify-start">
          {widgetData.data.map((i, index) => (
            <AvailabilityTick
              key={`tick-${index}`}
              targetPercentage={properties.targetPercentage!}
              data={i}
              tickWidth={tickWidth}
              horizontalSpacing={horizontalSpacing}
            />
          ))}
        </div>
        {properties.includeMaintenance && (
          <div className="flex text-neutral-dark-60 mt-2">
            <CircleCheckIcon className="mr-1 h-4 w-4 fill-current" />
            <Label size="extra-small">Include maintenance</Label>
          </div>
        )}
      </>
    ),
  };
}

interface AvailabilityTickProps {
  targetPercentage: number;
  data: WidgetDataItem;
  tickWidth: number;
  horizontalSpacing: number;
}

export const AvailabilityTick: React.FC<AvailabilityTickProps> = (props) => {
  const { data, horizontalSpacing, targetPercentage, tickWidth } = props;
  const hasValue = !!data.primaryValue && !!data.secondaryValue;
  let tooltipContent;
  let className;
  if (hasValue) {
    const actualPercentage = (data.primaryValue! / data.secondaryValue!) * 100;
    const meetsTarget = actualPercentage >= targetPercentage;
    tooltipContent = `${actualPercentage.toFixed(2)}%  ${toUTCDateString(new Date(data.startTime))}`;
    className = meetsTarget ? "h-3 bg-utility-success-20" : "h-4 bg-utility-critical-50";
  } else {
    tooltipContent = `(no data)  ${toUTCDateString(new Date(data.startTime))}`;
    className = "h-3 bg-neutral-dark-40";
  }

  return (
    <div
      style={{
        width: tickWidth,
        marginLeft: horizontalSpacing,
        marginRight: horizontalSpacing,
      }}
      className={classnames("cursor-pointer mb-3 shrink-0 rounded-sm", className)}
      data-tip={tooltipContent}
    ></div>
  );
};
