import { usePortalAppSecurityService } from "@wexinc/wex-common-web";
import { Endpoints } from "services/api";
import { SLARoutes } from "app/routes";
import { SLALoggedInUser, SLAPermissions } from "./models";
import { LoggedInUserConstructorArgs } from "@wexinc/wex-common-web";

const useSLASecurityService = () => {
  // This prevents the application from checking for an active okta session if generating a pdf.
  // This implementation is a bit hap-hazard... the correct way to do it would be to better split up
  // the security service since it is being used in far too many places.
  const disableAutomaticSessionCheck = window.location.pathname === "/dashboard-pdf";

  const securityService = usePortalAppSecurityService(
    (args: LoggedInUserConstructorArgs) => new SLALoggedInUser(args),
    Endpoints.copApiRoot,
    Endpoints.copWebLoginURL,
    SLAPermissions,
    { root: SLARoutes.root, loginCallback: SLARoutes.loginCallback },
    disableAutomaticSessionCheck,
  );

  return {
    gotoLogin: securityService.gotoLogin,
    logout: securityService.logout,
    securityStore: securityService.securityStore,
    userHasOrgPermission: securityService.userHasOrgPermission,
  };
};

export default useSLASecurityService;
