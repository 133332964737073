import { useEffect, useState } from "react";
import { getMetricTypeKey, Metric, MetricTypeKeys } from "services/metric";
import { SaveWidget, Widget } from "services/widget";
import useWidgetService from "services/widget/useWidgetService";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { max } from "lodash";
import {
  AddOrEditModal,
  CheckboxField,
  LoadableContentContainer,
  NumberField,
  TextAreaField,
  TextField,
} from "@wexinc/wex-ui";
import { Dashboard } from "services/dashboard";

interface Props {
  existingWidgets: Widget[];
  dashboard: Dashboard;
  associatedMetricOrExistingWidget: Metric | Widget;
  onAddedOrUpdated: (metric: Widget) => Promise<void>;
  onCancel: () => void;
}

const isWidget = (obj: Metric | Widget): obj is Widget => {
  return "metric" in obj;
};

interface WidgetWithMetricTypeKey extends Widget {
  metricTypeKey: string;
}

const AddOrUpdateWidgetModal: React.FC<Props> = (props) => {
  const widgetService = useWidgetService();
  const [saving, setIsSaving] = useState<boolean>(false);
  const { associatedMetricOrExistingWidget, dashboard, onAddedOrUpdated, existingWidgets, ...rest } = props;
  const [contentForMetricType, setContentForMetricType] = useState<ContentForMetricType>();

  const existingWidget: Widget | null = isWidget(associatedMetricOrExistingWidget)
    ? (associatedMetricOrExistingWidget as Widget)
    : null;
  const metric = isWidget(associatedMetricOrExistingWidget)
    ? associatedMetricOrExistingWidget.metric
    : (associatedMetricOrExistingWidget as Metric);

  const defaultValues = existingWidget
    ? { ...existingWidget }
    : widgetService.initializeNewWidget(associatedMetricOrExistingWidget as Metric);
  const metricTypeKey = getMetricTypeKey(metric.type);

  (defaultValues as WidgetWithMetricTypeKey).metricTypeKey = metricTypeKey;

  function getNextDisplayOrder() {
    return existingWidgets.length ? max(existingWidgets.map((i) => i.displayOrder))! + 1 : 1;
  }

  async function onSubmit(widget: Widget) {
    setIsSaving(true);

    const isNewWidget = !widget.id || widget.id <= 0;
    const saveWidget: SaveWidget = {
      id: isNewWidget ? undefined : widget.id,
      metricId: widget.metric.id,
      properties: widget.properties,
      enabled: widget.enabled,
      displayOrder: isNewWidget ? getNextDisplayOrder() : widget.displayOrder,
    };
    const addedOrUpdatedMetric = await widgetService.saveWidget(dashboard.id, saveWidget);

    return onAddedOrUpdated(addedOrUpdatedMetric);
  }

  useEffect(() => {
    setContentForMetricType(getContentForMetricType(metricTypeKey));
  }, [metricTypeKey]);

  if (!contentForMetricType) {
    return null;
  }

  return (
    <AddOrEditModal
      modalClassNames="w-[550px] min-w-min !max-w-2xl"
      formProps={{
        onSubmit,
        options: {
          defaultValues: defaultValues,
          ...(contentForMetricType.schema ? { resolver: yupResolver(contentForMetricType.schema) } : {}),
        },
      }}
      {...rest}
      titleOrHeader={existingWidget ? `Edit ${metric.name}` : "Create widget"}
    >
      <LoadableContentContainer
        showLoading={saving}
        message="Saving..."
      />
      <TextField
        name="metric.name"
        label="Name"
        readonly
      />
      <div className="flex">
        <TextField
          label="Type"
          name="metricTypeKey"
          containerClassName="w-1/3 mr-1"
          readonly
        />
        <TextField
          name="metric.dataId"
          label="Data ID"
          containerClassName="w-2/3 ml-1"
          readonly
        />
      </div>
      <TextAreaField
        name="metric.description"
        label="Description"
        readonly
      />
      {contentForMetricType.dynamicContent}
    </AddOrEditModal>
  );
};

export default AddOrUpdateWidgetModal;

interface ContentForMetricType {
  schema: yup.AnyObjectSchema | null;
  dynamicContent: React.ReactElement | null;
}

// Dead-simple dynamic content and validation. This isn't super-sophisticated or elegant
// so if we find that metric Types are added more frequently we can adjust this approach.
function getContentForMetricType(type: MetricTypeKeys): ContentForMetricType {
  switch (type) {
    case "Availability": {
      const schema = yup.object().shape({
        properties: yup.object().shape({
          targetPercentage: yup
            .number()
            .typeError("Target % must be a number from 1 to 100.")
            .required("Target % is required.")
            .min(1, "Min value of 1.")
            .max(100, "Max value of 100."),
          includeMaintenance: yup.boolean(),
        }),
      });
      const dynamicContent = (
        <>
          <NumberField
            name="properties.targetPercentage"
            label="Target %"
            containerClassName="w-1/2 mr-2"
            showRequiredDot
          />
          <CheckboxField
            name="properties.includeMaintenance"
            label="Include maintenance"
            containerClassName="ml-2"
          />
        </>
      );

      return { schema, dynamicContent };
    }
    case "Performance": {
      const schema = yup.object().shape({
        properties: yup.object().shape({
          targetResponseTime: yup
            .number()
            .typeError("Must be a number for max milliseconds.")
            .required("Response max is required."),
        }),
      });
      const dynamicContent = (
        <div className="flex">
          <NumberField
            name="properties.targetResponseTime"
            label="Response max (in milliseconds)"
            className="w-1/2"
            numberOfDigitsAllowed={0}
            showRequiredDot
          />
        </div>
      );
      return { schema, dynamicContent };
    }
    default:
      return { schema: null, dynamicContent: null };
  }
}
