import { getFetch } from "@wexinc/wex-common-web";
import { Endpoints } from "services/api/index";

const { get } = getFetch(Endpoints.apiRoot);

async function getLastUpdated(): Promise<Date | null> {
  const result = await get<string | null>({
    relativeRoute: "tasks/last-updated",
  });
  return result ? new Date(Date.parse(result)) : null;
}

export { getLastUpdated };
