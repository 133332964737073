// Environment variables expected to be injected into the application
// If a variable is added below, it should be added to entrypoint.sh as well
interface EnvironmentVariables {
  apiRoot: string | undefined;
  copApiRoot: string | undefined;
  pdfApiRoot: string | undefined;
  copWebLoginURL: string | undefined;
  sentryDSN: string | undefined;
  sentryEnvironment: string | undefined;
  oktaClientId: string | undefined;
  oktaIssuer: string;
}

// entrypoint.sh creates static/js/env.js which is loaded in a production build
// note, this doesn't apply to running locally through yarn/npm
interface Window {
  loadedEnv?: EnvironmentVariables;
}

// get loadedEnv as a temporary variable and give it a default
const e = (window as Window).loadedEnv || ({} as EnvironmentVariables);

// exposed Environment variables
// note process.env.* variables are injected at build or when running through yarn
export const Env: EnvironmentVariables = {
  apiRoot: e.apiRoot || process.env.REACT_APP_API_ROOT,
  copApiRoot: e.copApiRoot || process.env.REACT_APP_COP_API_URL,
  pdfApiRoot: e.pdfApiRoot || process.env.REACT_APP_PDF_SERVICE_ROOT,
  copWebLoginURL: e.copWebLoginURL || process.env.REACT_APP_COP_LOGIN_URL,
  sentryDSN: e.sentryDSN || process.env.REACT_APP_SENTRY_DSN,
  sentryEnvironment: e.sentryEnvironment || process.env.ENVIRONMENT || "local",
  oktaClientId: e.oktaClientId || process.env.REACT_APP_OKTA_CLIENT_ID,
  oktaIssuer: e.oktaIssuer || process.env.REACT_APP_OKTA_ISSUER || "",
};
