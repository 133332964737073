import { Endpoints } from "services/api";
import { SaveMetric, Metric, MetricType } from ".";
import { getFetch } from "@wexinc/wex-common-web";
import { organizationsApiRoot } from "../organization/api";
import { TimeRange, WidgetDataItem } from "services/widget";

const { get, put, doDelete } = getFetch(Endpoints.apiRoot);

export function saveMetric(organizationId: string, metric: SaveMetric | Metric): Promise<Metric> {
  return put({
    relativeRoute: `${organizationsApiRoot}/${organizationId}/metrics`,
    body: metric,
  });
}

export function deleteMetric(organizationId: string, metricId: number): Promise<Response> {
  return doDelete({
    relativeRoute: `${organizationsApiRoot}/${organizationId}/metrics/${metricId}`,
  });
}

export function getAvailableMetrics(organizationId: string): Promise<Metric[]> {
  return get({
    relativeRoute: `${organizationsApiRoot}/${organizationId}/available-metrics/all`,
  });
}

export function getMetricPreviewData(
  organizationId: string,
  metricType: MetricType,
  dataDogId: string,
  timeRange: TimeRange,
  dataFilter?: string,
): Promise<WidgetDataItem[]> {
  const queryParams: { [key: string]: string } = {
    start: timeRange.startTime.toISOString(),
    end: timeRange.endTime.toISOString(),
  };
  if (dataFilter) {
    queryParams.dataFilter = dataFilter;
  }

  return get({
    relativeRoute: `${organizationsApiRoot}/${organizationId}/metric-preview-data/${metricType}/${dataDogId}`,
    queryParams,
  });
}

// /api/organizations/:organizationId/metric-preview-data/:metricType/:dataId"
