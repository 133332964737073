import { OrganizationInfo } from "services/organization/models";

/**
 * A "data source" of a widget
 */
export interface SaveMetric {
  /**
   * Must not be blank
   * will be trimmed upon save
   */
  name: string;

  type: MetricType;

  dataId: string;

  description?: string;

  privateToOrganization: boolean;

  dataFilter?: string;
}

/**
 * A "data source" of a widget
 */
export interface Metric extends SaveMetric {
  id: number;
  owningOrganization: OrganizationInfo;
}

/**
 * Enum for Types of widgets
 */
export enum MetricType {
  Availability = "AVAILABILITY",
  Performance = "PERFORMANCE",
  Count = "COUNT",
}

export const MetricTypeKeyValues = Object.keys(MetricType).map((key) => ({
  label: key,
  value: (MetricType as never)[key],
}));

export type MetricTypeKeys = keyof typeof MetricType;

export function getMetricTypeKey(metricType: MetricType) {
  return MetricTypeKeyValues.find((i) => i.value === metricType)!.label as MetricTypeKeys;
}
