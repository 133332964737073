export enum SLARoutes {
  root = "/",
  dashboard = "admin/dashboard",
  loginCallback = "/login/callback",
}

export enum SLAAdminRoutes {
  root = ":dashboardId",
  metricLibrary = "metrics",
}
