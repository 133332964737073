import { getFetch } from "@wexinc/wex-common-web";
import { Endpoints } from "services/api";

const { get } = getFetch(Endpoints.pdfServiceRoot);

function getPDF(url: string): Promise<Response> {
  return get({
    relativeRoute: null,
    queryParams: {
      url,
    },
    responseSuccessHandler: (response) => Promise.resolve(response),
  });
}

export { getPDF };
