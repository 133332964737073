import { getFetch } from "@wexinc/wex-common-web";
import { Endpoints } from "../api";
import { OrganizationInfo, OrganizationLineage } from "./models";

export const organizationsApiRoot = "organizations";

const { get } = getFetch(Endpoints.apiRoot);

function getOrganizationLineage(id: string): Promise<OrganizationLineage> {
  return get({
    relativeRoute: `${organizationsApiRoot}/${id}/children`,
  });
}

function getTopLevelOrganizations(): Promise<OrganizationInfo[]> {
  return get({
    relativeRoute: `${organizationsApiRoot}/top-level/all`,
  });
}

export { getOrganizationLineage, getTopLevelOrganizations };
