import { useEffect, useState } from "react";
import useDashboardService from "services/dashboard";
import useMetricService from "services/metric/useMetricService";
import { Metric, MetricType } from "services/metric";
import { Widget } from "services/widget";
import {
  Button,
  Heading,
  Label,
  SearchInput,
  CheckIcon,
  ConfirmModal,
  EllipsisVerticalIcon,
  LoadableContentContainer,
  LockIcon,
  PenIcon,
  MinusIcon,
  PlusIcon,
  Popover,
  TrashIcon,
  ClientPaginatedGrid,
} from "@wexinc/wex-ui";
import AddOrUpdateMetricModal from "./components/AddOrUpdateMetricModal";
import useWidgetService from "services/widget/useWidgetService";
import React from "react";
import { Cell, Column } from "react-table";
import { debounce } from "lodash";
import AddOrUpdateWidgetModal from "./components/AddOrUpdateWidgetModal";
import { getKeyName } from "util/enum-helpers";
import useSLASecurityService from "services/security";

const MetricLibrary: React.FC = () => {
  const dashboardService = useDashboardService();
  const [showAddEditMetricModal, setShowAddEditMetricModal] = useState<{
    show: boolean;
    existingMetric?: Metric;
  }>({ show: false });
  const [showCreateWidgetModal, setShowCreateWidgetModal] = useState<{
    associatedMetricOrExistingWidget: Metric | Widget;
  }>();
  const [showMetricActionsConfirmationModal, setShowMetricActionsConfirmationModal] = useState<{
    onOk: () => void;
    title: string;
    content?: JSX.Element;
    okButtonText: string;
  }>();
  const metricService = useMetricService();
  const widgetService = useWidgetService();
  const [metrics, setMetrics] = useState<Metric[]>();
  const [widgets, setWidgets] = useState<Widget[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState<string>();
  const [canEditMetrics, setCanEditMetrics] = useState(false);
  const securityService = useSLASecurityService();

  if (!dashboardService.dashboardStore.currentDashboard) {
    return null;
  }

  const currentDashboard = dashboardService.dashboardStore.currentDashboard;
  useEffect(() => {
    securityService.userHasOrgPermission(currentDashboard.organization.id, "editMetrics").then(setCanEditMetrics);
  }, [currentDashboard.organization.id]);

  async function loadData() {
    setIsLoading(true);
    const [metrics, widgets] = await Promise.all([
      metricService.getAvailableMetrics(currentDashboard.organization.id),
      widgetService.getWidgetsForDashboard(currentDashboard.id),
    ]);
    setMetrics(metrics);
    setWidgets(widgets);
    setIsLoading(false);
  }

  async function onMetricAddedOrUpdated() {
    await loadData();
    closeMetricModal();
  }

  function closeMetricModal() {
    setShowAddEditMetricModal({ show: false });
  }

  function showMetricModal(existingMetric?: Metric) {
    setShowAddEditMetricModal({ show: true, existingMetric });
  }

  async function onWidgetAdded() {
    await loadData();
    setShowCreateWidgetModal(undefined);
  }

  async function deleteMetric(metric: Metric) {
    setIsLoading(true);
    await metricService.deleteMetric(currentDashboard?.organization.id, metric.id);
    setShowMetricActionsConfirmationModal(undefined);
    loadData();
  }

  async function deleteWidget(widget: Widget) {
    setIsLoading(true);
    await widgetService.deleteWidget(currentDashboard?.id, widget.id);
    setShowMetricActionsConfirmationModal(undefined);
    loadData();
  }

  function getAssociatedWigetForMetric(metric: Metric): Widget | undefined {
    return widgets?.find((i) => i.metric.id === metric.id);
  }

  function OnMetricAction(action: MetricActions, metric: Metric) {
    switch (action) {
      case "edit":
        showMetricModal(metric);

        break;
      case "delete":
        {
          const associatedWidget = getAssociatedWigetForMetric(metric);
          const content = associatedWidget ? (
            <Label>{`This will also delete the associated Widget ${
              metric.privateToOrganization === false
                ? "and all widgets based on this Metric that are in use on other dashboards"
                : ""
            }.`}</Label>
          ) : undefined;
          setShowMetricActionsConfirmationModal({
            onOk: () => deleteMetric(metric),
            title: "Delete this Metric?",
            content,
            okButtonText: "Delete",
          });
        }
        break;
      case "edit-widget":
        setShowCreateWidgetModal({
          associatedMetricOrExistingWidget: getAssociatedWigetForMetric(metric)!,
        });
        break;
      case "remove-widget":
        {
          const widget = getAssociatedWigetForMetric(metric)!;

          setShowMetricActionsConfirmationModal({
            onOk: () => deleteWidget(widget),
            title: "Delete this Widget?",
            okButtonText: "Remove",
          });
        }
        break;
    }
  }

  const onSearch = debounce((value: string) => setSearch(value), 200);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return (
    <LoadableContentContainer showLoading={isLoading}>
      {currentDashboard && (
        <>
          <div className="flex items-center justify-between px-8">
            <Heading
              size="large"
              className="my-4"
            >
              Metric library
            </Heading>

            {canEditMetrics && (
              <div className="flex items-center">
                <Button
                  testId="create-new-metric"
                  onClick={() => showMetricModal()}
                >
                  <PlusIcon className="mr-2 fill-current" />
                  New metric
                </Button>
              </div>
            )}
          </div>
          <div className="flex items-center border-b border-t border-t-neutral-dark-20 border-b-neutral-dark-20">
            <SearchInput
              containerClassName="border-none h-9 w-48 ml-4"
              onChange={(e) => onSearch(e.currentTarget.value)}
              name="search-metrics"
            />
          </div>
          <div className="px-6">
            {!metrics?.length && (
              <Label
                size="medium"
                className="inline-block w-full text-center mt-16"
              >
                (No Metrics in library)
              </Label>
            )}
            {metrics && metrics.length > 0 && (
              <MetricsGrid
                onMenuAction={OnMetricAction}
                organizationId={currentDashboard!.organization.id}
                globalFilter={search}
                metrics={metrics!}
                widgets={widgets!}
                onAddWidget={(associatedMetric) =>
                  setShowCreateWidgetModal({
                    associatedMetricOrExistingWidget: associatedMetric,
                  })
                }
                canEditMetrics={canEditMetrics}
              />
            )}
          </div>
          {showAddEditMetricModal.show && (
            <AddOrUpdateMetricModal
              existingMetric={showAddEditMetricModal.existingMetric}
              parentDashboard={currentDashboard!}
              onAddedOrUpdated={onMetricAddedOrUpdated}
              onCancel={closeMetricModal}
            />
          )}
          {showCreateWidgetModal && (
            <AddOrUpdateWidgetModal
              existingWidgets={widgets!}
              associatedMetricOrExistingWidget={showCreateWidgetModal.associatedMetricOrExistingWidget}
              dashboard={currentDashboard!}
              onAddedOrUpdated={onWidgetAdded}
              onCancel={() => setShowCreateWidgetModal(undefined)}
            />
          )}
          {showMetricActionsConfirmationModal && (
            <ConfirmModal
              onOk={showMetricActionsConfirmationModal.onOk}
              onCancel={() => setShowMetricActionsConfirmationModal(undefined)}
              title={showMetricActionsConfirmationModal.title}
              mode="critical"
              okButtonText={showMetricActionsConfirmationModal.okButtonText}
            >
              {showMetricActionsConfirmationModal.content}
            </ConfirmModal>
          )}
        </>
      )}
    </LoadableContentContainer>
  );
};

type onAddWidget = (associatedMetric: Metric) => void;

interface MetricsGridProps {
  globalFilter: string | undefined;
  metrics: Metric[];
  widgets: Widget[];
  onAddWidget: onAddWidget;
  organizationId: string;
  onMenuAction: OnMetricAction;
  canEditMetrics: boolean;
}

const MetricsGrid: React.FC<MetricsGridProps> = (props) => {
  const { organizationId, onMenuAction, metrics, onAddWidget, globalFilter, widgets, canEditMetrics } = props;
  const columns = React.useMemo(
    () =>
      getColumns({
        widgets,
        onAddWidget,
        onMenuAction,
        organizationId,
        canEditMetrics,
      }),
    [widgets, onAddWidget, organizationId, onMenuAction, canEditMetrics],
  ) as Array<Column<Metric>>;

  return (
    <ClientPaginatedGrid<Metric>
      memoizedColumns={columns}
      getDataFromApi={async () => metrics}
      globalFilter={globalFilter}
      defaultSort={"name"}
    />
  );
};

interface GetColumnsArgs {
  widgets: Widget[];
  onAddWidget: onAddWidget;
  onMenuAction: OnMetricAction;
  organizationId: string;
  canEditMetrics: boolean;
}

function getColumns(args: GetColumnsArgs) {
  const result = [
    {
      Header: "METRIC",
      accessor: "name",
      Cell: ({ row }: Cell<Metric>) => {
        const metric = row.original;
        return (
          <Label className="flex py-1 whitespace-nowrap">
            {metric.name}
            {metric.privateToOrganization && <LockIcon className="ml-2" />}
          </Label>
        );
      },
    },
    {
      Header: "ORGANIZATION",
      accessor: "owningOrganization.name",
      Cell: ({ row }: Cell<Metric>) => {
        const metric = row.original;
        return (
          <Label
            size="small"
            className="bg-neutral-dark-10 px-4 py-1 rounded-lg whitespace-nowrap"
          >
            {metric.owningOrganization.name}
          </Label>
        );
      },
    },
    {
      Header: "TYPE",
      accessor: "type",
      Cell: ({ row }: Cell<Metric>) => getKeyName(MetricType, row.original.type),
      width: 100,
    },
    {
      Header: "DATA ID",
      accessor: "dataId",
      width: 60,
    },
    {
      Header: "DESCRIPTION",
      accessor: "description",
      Cell: ({ row }: Cell<Metric>) => {
        const metric = row.original;
        return (
          <div
            className="overflow-hidden line-clamp-1"
            data-tip={metric.description}
          >
            {metric.description}
          </div>
        );
      },
    },
    {
      Header: () => null,
      id: "is-added",
      Cell: ({ row }: Cell<Metric>) => {
        const metric = row.original;
        const isAlreadyAWidget = checkIfMetricIsAlreadyAWidget(metric, args.widgets);
        return isAlreadyAWidget ? (
          <div
            className="flex text-utility-success-60"
            data-test-id="widget-added"
          >
            <CheckIcon className="fill-current mr-2" /> Added
          </div>
        ) : (
          <Button
            testId={`add-as-widget-${row.index}`}
            buttonType="tertiary"
            doPadXAxis={false}
            onClick={() => args.onAddWidget(metric)}
            size="default"
            className="px-2 whitespace-nowrap"
          >
            <PlusIcon className="mr-2" />
            Add as widget
          </Button>
        );
      },
      width: 100,
    },
  ];

  if (args.canEditMetrics) {
    result.push({
      Header: () => null,
      id: "actions",
      width: 32,
      Cell: ({ row }: Cell<Metric>) => {
        const metric = row.original;
        const isAlreadyAWidget = checkIfMetricIsAlreadyAWidget(metric, args.widgets);
        return (
          <ActionsMenu
            isAlreadyAWidget={isAlreadyAWidget}
            metric={metric}
            {...args}
          />
        );
      },
    });
  }
  return result;
}

function checkIfMetricIsAlreadyAWidget(metric: Metric, widgets: Widget[]) {
  return !!widgets.find((i) => i.metric.id === metric.id);
}

type MetricActions = "edit" | "delete" | "edit-widget" | "remove-widget";
type OnMetricAction = (action: MetricActions, metric: Metric) => void;

interface ActionsMenuProps {
  metric: Metric;
  organizationId: string;
  onMenuAction: OnMetricAction;
  isAlreadyAWidget: boolean;
}

const ActionsMenu: React.FC<ActionsMenuProps> = (props) => {
  const { organizationId, isAlreadyAWidget, metric, onMenuAction } = props;
  const canEditOrDelete = organizationId === metric.owningOrganization.id;

  if (!canEditOrDelete && !isAlreadyAWidget) {
    return null;
  }

  return (
    <Popover
      testId="show-metric-actions-menu"
      className="w-34 p-2"
      anchorButtonClassName="w-8 h-8 flex justify-center items-center"
      getAnchorContent={() => (
        <div>
          <EllipsisVerticalIcon />
        </div>
      )}
    >
      {() => (
        <ul>
          {canEditOrDelete && (
            <>
              <li className="p-1">
                <Button
                  testId="edit-metric"
                  buttonType="secondary-neutral-dark"
                  className="w-full"
                  align="left"
                  onClick={() => onMenuAction("edit", metric)}
                >
                  <PenIcon className="mr-2" />
                  Edit
                </Button>
              </li>
              <li className="p-1">
                <Button
                  testId="delete-metric"
                  buttonType="secondary-critical"
                  className="w-full"
                  align="left"
                  onClick={() => onMenuAction("delete", metric)}
                >
                  <TrashIcon className="mr-2" />
                  Delete
                </Button>
              </li>
            </>
          )}
          {isAlreadyAWidget && (
            <>
              <li className="p-1">
                <Button
                  testId="edit-widget"
                  buttonType="secondary-neutral-dark"
                  className="w-full"
                  align="left"
                  onClick={() => onMenuAction("edit-widget", metric)}
                >
                  <PenIcon className="mr-2" />
                  Edit Widget
                </Button>
              </li>
              <li className="p-1">
                <Button
                  testId="remove-widget"
                  buttonType="secondary-neutral-dark"
                  className="w-full"
                  align="left"
                  onClick={() => onMenuAction("remove-widget", metric)}
                >
                  <MinusIcon className="mr-2" />
                  Remove Widget
                </Button>
              </li>
            </>
          )}
        </ul>
      )}
    </Popover>
  );
};

export default MetricLibrary;
