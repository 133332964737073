import { SaveMetric, Metric } from ".";
import * as api from "./api";

const useMetricService = () => {
  async function getAvailableMetrics(organizationId: string) {
    const metrics = await api.getAvailableMetrics(organizationId);

    return metrics.sort((a: Metric, b: Metric) => {
      return a.name.localeCompare(b.name);
    });
  }

  function saveMetric(organizationId: string, metric: SaveMetric | Metric) {
    return api.saveMetric(organizationId, metric);
  }

  function deleteMetric(organizationId: string, metricId: number) {
    return api.deleteMetric(organizationId, metricId);
  }

  return {
    deleteMetric,
    getAvailableMetrics,
    saveMetric,
  };
};

export default useMetricService;
