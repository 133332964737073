import { SLAAdminRoutes, SLARoutes } from "app/routes";
import React, { useState } from "react";
import { generatePath, Link, Outlet, useLocation, useResolvedPath } from "react-router-dom";
import { DownArrowIcon } from "@wexinc/wex-ui";
import classNames from "classnames";
import useDashboardService from "services/dashboard";
import { knownQuerytringParams } from "util/querystring-helper";

const AdminLayout: React.FC = () => {
  const currentDashboard = useDashboardService().dashboardStore.currentDashboard;

  if (!currentDashboard) {
    return null;
  }

  return (
    <div className="flex overflow-auto h-full">
      <div className="min-w-[224px] pt-24 bg-neutral-dark-5 border-r border-r-neutral-dark-20 ">
        <LeftNav
          dashboardId={currentDashboard.id}
          organizationId={currentDashboard.organization.id}
        />
      </div>
      <div
        className="pt-16 relative h-full"
        style={{ width: "calc(100% - 224px)" }}
      >
        <Outlet />
      </div>
    </div>
  );
};

const leftNavData: {
  text: string;
  to: string;
}[] = [
  {
    text: "Added widgets",
    to: SLAAdminRoutes.root,
  },
  {
    text: "Metric Library",
    to: `${SLAAdminRoutes.root}/${SLAAdminRoutes.metricLibrary}`,
  },
];

const LeftNav: React.FC<{ dashboardId: number; organizationId: string }> = (props) => {
  return (
    <ul className="pl-1">
      <li className="px-4 pb-4">
        <Link
          to={`${SLARoutes.root}?${knownQuerytringParams.dashboardId}=${props.dashboardId}${
            props.organizationId ? `&${knownQuerytringParams.organizationId}=${props.organizationId}` : ""
          }`}
          className="flex items-center"
        >
          <DownArrowIcon className="mr-4 rotate-90" />
          Dashboard
        </Link>
      </li>
      {leftNavData.map((i) => {
        return (
          <li
            key={i.to}
            className="mr-4 mb-1"
          >
            <LeftNavItem
              dashboardId={props.dashboardId}
              {...i}
            />
          </li>
        );
      })}
    </ul>
  );
};

const LeftNavItem: React.FC<{
  text: string;
  to: string;
  dashboardId: number;
}> = (props) => {
  const { text, dashboardId, to } = props;
  const [isFocused, setIsFocused] = useState(false);
  const location = useLocation();
  const path = useResolvedPath(to);
  const route = generatePath(path.pathname, {
    dashboardId: dashboardId.toString(),
  });
  const isSelected = location.pathname === route;

  return (
    <Link
      data-test-id={text === "Widgets" ? "left-nav-widgets" : "left-nav-metric"}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      to={route}
      className={classNames(
        "focus:outline-none h-10 inline-block w-full rounded-r-lg",
        isSelected ? "bg-primary-10 text-neutral-dark-100 font-semibold" : "text-neutral-dark-70",
        isFocused ? "shadow-focus-1" : "",
      )}
    >
      <label className="flex items-center h-full relative pl-5 cursor-pointer">
        <div className={classNames("absolute w-1 h-full left-0", isSelected ? "bg-primary-50" : "")} />
        {text}
      </label>
    </Link>
  );
};

export default AdminLayout;
