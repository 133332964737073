import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import useDashboardService from "services/dashboard";
import { NotFoundError, useAppStatus } from "@wexinc/wex-common-web";

type AppBootstrapperProps = {
  children: React.ReactNode;
};

const AppBootstrapper: React.FC<AppBootstrapperProps> = (props) => {
  const dashboardService = useDashboardService();
  const [processing, setProcessing] = useState(true);
  const { showBoundary } = useErrorBoundary();
  const appStatus = useAppStatus();

  useEffect(() => {
    async function bootstrap() {
      try {
        const url = new URL(window.location.href);

        if (url.pathname.toLowerCase().indexOf("admin/dashboard") !== -1) {
          const match = /admin\/dashboard\/(\d+)/.exec(url.pathname);
          const dashboardId = match ? parseInt(match[1]) : null;

          if (dashboardId) {
            const dashboard = await dashboardService.getDashboard(dashboardId);
            dashboardService.setCurrentDashboard(dashboard);
          } else {
            throw new NotFoundError("Can't find dashboard id in url.");
          }
        }

        setProcessing(false);
        appStatus.setAppStatus("end-appbootstrap");
      } catch (e) {
        if (e instanceof NotFoundError) {
          appStatus.setGlobalMessage("Oops... we can't find the dashboard.");
        } else {
          showBoundary(e);
        }
      }
    }
    bootstrap();
    appStatus.setAppStatus("begin-appbootstrap");
    // eslint-disable-next-line
  }, []);

  return <>{!processing && props.children}</>;
};

export default AppBootstrapper;
