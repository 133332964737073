import { BrowserRouter } from "react-router-dom";
import ApplicationRoutes from "./ApplicationRoutes";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useEffect } from "react";
import DashboardView from "pages/Dashboard/DashboardView";
import { DashboardProvider } from "services/dashboard/DashboardProvider";
import usePDFService from "services/pdf/usePDFService";
import { AppMessageDisplay, AppStatusProvider, getSecurityProvider } from "@wexinc/wex-common-web";
import { Env } from "util/environment-helper";

const oktaAuth = new OktaAuth({
  clientId: Env.oktaClientId,
  issuer: Env.oktaIssuer,
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  services: { autoRenew: false },
});

const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
  // After handling an okta login callback, this function is called, redirecting the user
  // back to the original URL where the login was initiated
  window.location.replace(toRelativeUrl(originalUri || "/", window.location.origin));
};

const ApiVersionString = Env.apiRoot?.startsWith("/")
  ? `SLA API Version: ${window.location.origin}${Env.apiRoot}/public/management/versions`
  : `SLA API Version: ${Env.apiRoot}/public/management/versions`;

function App() {
  const pdfService = usePDFService();

  useEffect(() => {
    oktaAuth.start();
    // log app + api versions on load for debugging purposes - WSD-625
    console.log("SLA Web Version: " + process.env.REACT_APP_VERSION);
    console.log(ApiVersionString);
  }, []);

  const SecurityProvider = getSecurityProvider();

  return (
    <AppStatusProvider>
      <BrowserRouter>
        <Security
          oktaAuth={oktaAuth}
          restoreOriginalUri={restoreOriginalUri}
        >
          <SecurityProvider>
            {pdfService.isPdfView ? (
              <DashboardProvider>
                <DashboardView />
              </DashboardProvider>
            ) : (
              <>
                <AppMessageDisplay />
                <ApplicationRoutes />
              </>
            )}
          </SecurityProvider>
        </Security>
      </BrowserRouter>
    </AppStatusProvider>
  );
}

export default App;

export const __DEV = process.env.NODE_ENV === "development";
