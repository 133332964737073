import { Endpoints } from "services/api";
import { AddDashboard, Dashboard } from ".";
import { getFetch } from "@wexinc/wex-common-web";
import { OrganizationInfo } from "services/organization/models";

export const dashboardsApiRoot = "dashboards";

const { get, put } = getFetch(Endpoints.apiRoot);

function getAllDashboards(organizationId: string): Promise<Dashboard[]> {
  return get({
    relativeRoute: `organizations/${organizationId}/${dashboardsApiRoot}/all`,
  });
}

function getDashboard(id: number): Promise<Dashboard> {
  return get({
    relativeRoute: `${dashboardsApiRoot}/${id}`,
  });
}

function saveDashboard(dashboard: AddDashboard | Dashboard): Promise<Dashboard> {
  return put({
    relativeRoute: `organizations/${dashboard.organization.id}/dashboards`,
    body: dashboard,
  });
}

function getSharedOrganizationsForDashboard(dashboardId: number): Promise<OrganizationInfo[]> {
  return get({
    relativeRoute: `${dashboardsApiRoot}/${dashboardId}/shared-organizations`,
  });
}

function setSharedOrganizationsForDashboard(dashboardId: number, organizationIds: string[]): Promise<Dashboard> {
  return put({
    relativeRoute: `${dashboardsApiRoot}/${dashboardId}/shared-organizations`,
    body: organizationIds,
  });
}

export {
  getAllDashboards,
  getDashboard,
  getSharedOrganizationsForDashboard,
  saveDashboard,
  setSharedOrganizationsForDashboard,
};
