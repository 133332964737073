import { getMetricTypeKey } from "services/metric";
import { WidgetData } from "services/widget";
import getAvailabilityWidgetContent from "./widget-content/Availability";
import { getCountWidgetContent } from "./widget-content/Count";
import { getPerformanceWidgetContent } from "./widget-content/Performance";

export interface WidgetContent {
  headerContent?: React.ReactElement;
  bodyContent?: React.ReactElement;
}

export function getWidgetContent(widgetData: WidgetData): WidgetContent {
  const metricTypeKey = getMetricTypeKey(widgetData.widget.metric.type);
  switch (metricTypeKey) {
    case "Availability":
      return getAvailabilityWidgetContent(widgetData);
    case "Count":
      return getCountWidgetContent(widgetData);
    case "Performance":
      return getPerformanceWidgetContent(widgetData);
    default:
      throw Error(`Unsupported metrictype key: '${metricTypeKey}'.`);
  }
}
