import { useEffect, useState } from "react";
import useDashboardService, { Dashboard } from "services/dashboard";
import {
  ConfirmModal,
  EllipsisVerticalIcon,
  LoadableContentContainer,
  MinusIcon,
  PenIcon,
  PlusIcon,
  Popover,
  Button,
  Heading,
  Label,
  SearchInput,
  ToggleButton,
  UpRightArrow,
  DragDroppableGrid,
} from "@wexinc/wex-ui";
import useWidgetService from "services/widget/useWidgetService";
import { AvailabilityWidgetProperties, PerformanceWidgetProperties, SaveWidget, Widget } from "services/widget";
import AddOrUpdateDashboardModal from "components/dashboard/AddOrUpdateDashboardModal";
import React from "react";
import { Cell, Column } from "react-table";
import { debounce } from "lodash";
import { getKeyName } from "util/enum-helpers";
import { MetricType } from "services/metric";
import AddOrUpdateWidgetModal from "./components/AddOrUpdateWidgetModal";
import { produce } from "immer";
import { ReactComponent as EmptyLogo } from "assets/images/empty-logo.svg";
import { useNavigate } from "react-router-dom";
import useSLAOrganizationService from "services/organization";
import ShareDashboardModal from "./components/ShareDashboardModal";
import useSLASecurityService from "services/security";
import { OrganizationLineage } from "services/organization/models";

const Widgets: React.FC = () => {
  const dashboardService = useDashboardService();
  const widgetService = useWidgetService();
  const slaOrganizationService = useSLAOrganizationService();
  const navigate = useNavigate();
  const [widgets, setWidgets] = useState<Widget[] | null>(null);
  const [showDashboardEditModal, setShowDashboardEditModal] = useState(false);
  const [search, setSearch] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState<OrganizationLineage>();
  const [canEditDashboard, setCanEditDashboard] = useState(false);
  const [canShareDashboard, setCanShareDashboard] = useState(false);
  const [showShareDashboardModal, setShowShareDashboardModal] = useState(false);
  const [showEditWidgetModal, setShowEditWidgetModal] = useState<Widget>();
  const [showWidgetActionsConfirmationModal, setShowWidgetActionsConfirmationModal] = useState<{
    onOk: () => void;
    title: string;
    okButtonText: string;
  }>();
  const securityService = useSLASecurityService();

  const onSearch = debounce((value: string) => setSearch(value), 200);

  const currentDashboard = dashboardService.dashboardStore.currentDashboard!;

  async function loadData() {
    setIsLoading(true);
    const [widgets, organization, editable, shareable] = await Promise.all([
      widgetService.getWidgetsForDashboard(currentDashboard.id),
      slaOrganizationService.getOrganizationLineage(currentDashboard.organization.id),
      securityService.userHasOrgPermission(currentDashboard.organization.id, "editDashboards"),
      securityService.userHasOrgPermission(currentDashboard.organization.id, "shareDashboards"),
    ]);
    setWidgets(widgets);
    setOrganization(organization);
    setCanEditDashboard(editable);
    setCanShareDashboard(shareable);
    setIsLoading(false);
  }

  async function deleteWidget(widget: Widget) {
    setIsLoading(true);
    await widgetService.deleteWidget(currentDashboard!.id, widget.id);
    setShowWidgetActionsConfirmationModal(undefined);
    loadData();
  }

  async function toggleWidget(widget: Widget) {
    const updatedEnabledFlag = !widget.enabled;
    setWidgets(
      produce((draft) => {
        const widgetInState = draft?.find((i) => i.id === widget.id);
        if (widgetInState) {
          widgetInState.enabled = updatedEnabledFlag;
        }
      }),
    );
    const saveWidget: SaveWidget = {
      id: widget.id,
      metricId: widget.metric.id,
      properties: widget.properties,
      enabled: updatedEnabledFlag,
      displayOrder: widget.displayOrder,
    };
    widgetService.saveWidget(currentDashboard!.id, saveWidget);
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  async function onWidgetEdited() {
    setShowEditWidgetModal(undefined);
    await loadData();
  }

  function onWidgetAction(action: WidgetActions, widget: Widget) {
    switch (action) {
      case "edit":
        setShowEditWidgetModal(widget);
        break;
      case "remove":
        setShowWidgetActionsConfirmationModal({
          onOk: () => deleteWidget(widget),
          title: "Delete this Widget?",

          okButtonText: "Delete",
        });

        break;
      case "toggle-enable":
        toggleWidget(widget);

        break;
    }
  }

  function onDashboardEdited(updatedDashboard: Dashboard) {
    dashboardService.setCurrentDashboard(updatedDashboard);
    setShowDashboardEditModal(false);
  }

  function onWidgetsOrderChanged(newlyOrderedWidgets: Widget[]) {
    const finalWidgets = newlyOrderedWidgets.map((i, index) => {
      const clonedWidget = { ...i };
      clonedWidget.displayOrder = index + 1;
      return clonedWidget;
    });

    setWidgets(finalWidgets);
    widgetService.orderWidgets(
      currentDashboard!.id,
      finalWidgets.map((i) => i.id),
    );
  }

  return (
    <>
      <LoadableContentContainer
        showLoading={isLoading}
        className="!top-20"
      >
        {currentDashboard && organization && canEditDashboard && (
          <>
            <div className="flex items-center justify-between px-8">
              <div>
                <OrganizationLineageViewer organization={organization} />
                <div
                  data-test-id="edit-dashboard-name"
                  className="flex items-center cursor-pointer"
                  onClick={() => setShowDashboardEditModal(true)}
                >
                  <Heading size="large">{currentDashboard!.name}</Heading>
                  <Button
                    testId="edit-dashboard-name-button"
                    buttonType="secondary-neutral"
                    className="py-1 px-2"
                    blurOnClick
                  >
                    <PenIcon className="w-4 h-4 ml-2 " />
                  </Button>
                </div>
              </div>
              <div className="flex items-center">
                {canShareDashboard && (
                  <Button
                    testId="share-dashboard"
                    buttonType="secondary-neutral"
                    className="my-4 px-4 mr-2"
                    onClick={() => setShowShareDashboardModal(true)}
                  >
                    <UpRightArrow className="mr-2 fill-current" /> Share
                  </Button>
                )}
                <Button
                  testId="add-widget"
                  className="my-4 px-4 mr-2"
                  onClick={() => navigate("metrics")}
                >
                  <PlusIcon className="mr-2" /> Add widgets
                </Button>
              </div>

              {showDashboardEditModal && (
                <AddOrUpdateDashboardModal
                  organization={organization!}
                  existingDashboard={currentDashboard}
                  onAddedOrUpdated={onDashboardEdited}
                  onCancel={() => setShowDashboardEditModal(false)}
                />
              )}
            </div>
            {widgets && widgets.length > 0 && (
              <div className="flex items-center border-b border-t border-t-neutral-dark-20 border-b-neutral-dark-20">
                <SearchInput
                  containerClassName="border-none h-9 w-48 ml-4"
                  onChange={(e) => onSearch(e.currentTarget.value)}
                  name="search-metrics"
                />
              </div>
            )}
            <div className="px-6">
              {!widgets?.length && (
                <div className="flex flex-col justify-center items-center mt-20">
                  <EmptyLogo />
                </div>
              )}
              {widgets && widgets.length > 0 && (
                <WidgetsGrid
                  onWidgetsOrderChanged={onWidgetsOrderChanged}
                  onMenuAction={onWidgetAction}
                  globalFilter={search}
                  widgets={widgets!}
                />
              )}
            </div>
          </>
        )}
        {!canEditDashboard && <div className="p-4">No permissions to edit this Dashboard or Widgets.</div>}
      </LoadableContentContainer>
      {showEditWidgetModal && (
        <AddOrUpdateWidgetModal
          existingWidgets={widgets!}
          associatedMetricOrExistingWidget={showEditWidgetModal}
          dashboard={currentDashboard!}
          onAddedOrUpdated={onWidgetEdited}
          onCancel={() => setShowEditWidgetModal(undefined)}
        />
      )}
      {showWidgetActionsConfirmationModal && (
        <ConfirmModal
          onOk={showWidgetActionsConfirmationModal.onOk}
          onCancel={() => setShowWidgetActionsConfirmationModal(undefined)}
          title={showWidgetActionsConfirmationModal.title}
          mode="critical"
          okButtonText={showWidgetActionsConfirmationModal.okButtonText}
        />
      )}
      {showShareDashboardModal && (
        <ShareDashboardModal
          dashboard={currentDashboard}
          onClose={() => setShowShareDashboardModal(false)}
        />
      )}
    </>
  );
};

interface WidgetsGridProps {
  globalFilter: string | undefined;
  widgets: Widget[];
  onMenuAction: OnWidgetAction;
  onWidgetsOrderChanged: (newlyOrderedWidgets: Widget[]) => void;
}

const WidgetsGrid: React.FC<WidgetsGridProps> = (props) => {
  const { onMenuAction, widgets, globalFilter, onWidgetsOrderChanged } = props;
  const columns = React.useMemo(() => getColumns(onMenuAction), [onMenuAction]) as Array<Column<Widget>>;

  return (
    <DragDroppableGrid<Widget>
      onItemsOrderChanged={onWidgetsOrderChanged}
      memoizedColumns={columns}
      memoizedData={widgets}
      globalFilter={globalFilter}
    />
  );
};

function getColumns(onMenuAction: OnWidgetAction) {
  return [
    {
      Header: "ACTIVE",
      disableSortBy: true,
      id: "is-added",
      minWidth: 60,
      width: 60,
      Cell: ({ row }: Cell<Widget>) => {
        const widget = row.original;
        return (
          <ToggleButton
            onChange={() => onMenuAction("toggle-enable", widget)}
            value={widget.enabled}
            name={`toggle-wiget-enable-${row.index}`}
          />
        );
      },
    },
    {
      Header: "NAME",
      disableSortBy: true,
      accessor: (widget: Widget) => widget.metric.name,
      Cell: ({ row }: Cell<Widget>) => {
        const widget = row.original;
        return (
          <Label
            size="small"
            className="whitespace-nowrap"
          >
            {widget.metric.name}
          </Label>
        );
      },
    },
    {
      Header: "ORGANIZATION",
      disableSortBy: true,
      accessor: (widget: Widget) => widget.metric.owningOrganization.name,
      Cell: ({ row }: Cell<Widget>) => {
        const widget = row.original;
        return (
          <Label
            size="small"
            className="bg-neutral-dark-10 px-4 py-1 rounded-lg whitespace-nowrap"
          >
            {widget.metric.owningOrganization.name}
          </Label>
        );
      },
    },
    {
      Header: "TYPE",
      disableSortBy: true,
      accessor: (widget: Widget) => getKeyName(MetricType, widget.metric.type),
      width: 100,
    },
    {
      Header: "DATA ID",
      disableSortBy: true,
      accessor: (widget: Widget) => widget.metric.dataId,
      width: 60,
    },
    {
      Header: "DESCRIPTION",
      disableSortBy: true,
      accessor: (widget: Widget) => widget.metric.description,
      Cell: ({ row }: Cell<Widget>) => {
        const widget = row.original;
        return (
          <div
            className="overflow-hidden line-clamp-1"
            data-tip={widget.metric.description}
          >
            {widget.metric.description}
          </div>
        );
      },
      minWidth: 225,
    },
    {
      Header: "TARGET %",
      disableSortBy: true,
      accessor: (widget: Widget) => {
        const availWidgetProperties = widget.properties as AvailabilityWidgetProperties;

        return availWidgetProperties?.targetPercentage ? (
          availWidgetProperties.targetPercentage.toFixed(2)
        ) : (
          <MinusIcon />
        );
      },
    },
    {
      Header: "TIME (ms)",
      disableSortBy: true,
      accessor: (widget: Widget) => {
        const targetResponseTime = (widget.properties as PerformanceWidgetProperties)["targetResponseTime"];
        return targetResponseTime || <MinusIcon />;
      },
    },
    {
      Header: () => null,
      disableSortBy: true,
      id: "actions",
      width: 32,
      Cell: ({ row }: Cell<Widget>) => {
        const widget = row.original;
        return (
          <ActionsMenu
            widget={widget}
            onMenuAction={onMenuAction}
          />
        );
      },
    },
  ];
}

type WidgetActions = "edit" | "remove" | "toggle-enable";
type OnWidgetAction = (action: WidgetActions, widget: Widget) => void;

interface ActionsMenuProps {
  widget: Widget;
  onMenuAction: OnWidgetAction;
}

const ActionsMenu: React.FC<ActionsMenuProps> = (props) => {
  const { onMenuAction, widget } = props;

  return (
    <Popover
      testId="show-widget-actions-menu"
      className="w-34 p-2"
      anchorButtonClassName="w-8 h-8 flex justify-center items-center"
      getAnchorContent={() => (
        <div>
          <EllipsisVerticalIcon />
        </div>
      )}
    >
      {() => (
        <ul>
          <li className="p-1">
            <Button
              testId="edit-widget"
              buttonType="secondary-neutral-dark"
              className="w-full"
              align="left"
              onClick={() => onMenuAction("edit", widget)}
            >
              <PenIcon className="mr-2" />
              Edit
            </Button>
          </li>

          <li className="p-1">
            <Button
              testId="remove-widget"
              buttonType="secondary-neutral-dark"
              className="w-full"
              align="left"
              onClick={() => onMenuAction("remove", widget)}
            >
              <MinusIcon className="mr-2" />
              Remove
            </Button>
          </li>
        </ul>
      )}
    </Popover>
  );
};

interface OrganizationLineageViewerProps {
  organization: OrganizationLineage;
}

const OrganizationLineageViewer: React.FC<OrganizationLineageViewerProps> = (props) => {
  const lineage: string[] = [props.organization.name];
  let parentInfo = props.organization.parent;
  while (parentInfo) {
    lineage.push(parentInfo.name);
    parentInfo = parentInfo.parent;
  }

  return (
    <div>
      {lineage.reverse().map((i, index) => {
        const isLast = index === lineage.length - 1;
        return (
          <React.Fragment key={i}>
            <Label>{i}</Label>
            {!isLast && <Label className="px-1">&#62;</Label>}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Widgets;
