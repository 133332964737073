import React, { useEffect, useState } from "react";
import useTaskService from "services/task";

export default function LastUpdated() {
  const taskService = useTaskService();
  const [updatedDate, setUpdatedDate] = useState<string | null>(null);
  useEffect(() => {
    const getDate = async () => {
      const date = await taskService.getLastUpdated();
      setUpdatedDate(date ? date?.toLocaleDateString() : null);
    };
    getDate();
  }, []);

  return (
    <div
      className="flex items-center text-neutral-dark-60"
      data-test-id="last-updated"
    >
      Last Updated: {updatedDate ? updatedDate : "Date Not Available"}
    </div>
  );
}
