export function addDays(date: Date, days: number) {
  const result = new Date(date.getTime());
  result.setDate(result.getDate() + days);
  return result;
}

export function toUTCDateString(date: Date) {
  return date.getUTCMonth() + 1 + "/" + date.getUTCDate() + "/" + date.getUTCFullYear();
}

export function getUTCDate(localDate: Date, timeSetTo?: "start-of-day" | "end-of-day") {
  const now_utc = Date.UTC(
    localDate.getFullYear(),
    localDate.getMonth(),
    localDate.getDate(),
    timeSetTo ? (timeSetTo === "start-of-day" ? 0 : 23) : localDate.getUTCHours(),
    timeSetTo ? (timeSetTo === "start-of-day" ? 0 : 59) : localDate.getUTCMinutes(),
    timeSetTo ? (timeSetTo === "start-of-day" ? 0 : 59) : localDate.getUTCMilliseconds(),
  );
  return new Date(now_utc);
}

export function getUTCDateFormatted(localDate: Date, format: "y-m-d-h-m" = "y-m-d-h-m") {
  const utcDate = getUTCDate(localDate);

  switch (format) {
    case "y-m-d-h-m":
      return `${utcDate.getUTCFullYear()}-${(utcDate.getUTCMonth() + 1).toString().padStart(2, "0")}-${utcDate
        .getUTCDate()
        .toString()
        .padStart(2, "0")}-${utcDate.getUTCHours().toString().padStart(2, "0")}-${utcDate
        .getUTCMinutes()
        .toString()
        .padStart(2, "0")}`;
  }
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export function getUTCShortMonthName(date: Date) {
  return monthNames[date.getUTCMonth()];
}

export function getDayOfYear(date: Date) {
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  );
}
