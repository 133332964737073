import { OrganizationPicker } from "@wexinc/wex-common-web";
import {
  Button,
  ChevronDownIcon,
  ConfirmModal,
  FilePdfIcon,
  GearIcon,
  Heading,
  LinkButton,
  LoadableContentContainer,
  OptionValueLabel,
  Select,
  WexLogo,
} from "@wexinc/wex-ui";
import { __DEV } from "app/App";
import { SLARoutes } from "app/routes";
import { ReactComponent as EmptyLogo } from "assets/images/empty-logo.svg";
import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import useDashboardService, { Dashboard } from "services/dashboard";
import useSLAOrganizationService from "services/organization";
import { OrganizationInfo, OrganizationLineage } from "services/organization/models";
import usePDFService from "services/pdf/usePDFService";
import useSLAPortalAppService from "services/portal-app/useSLAPortalAppService";
import useSLASecurityService from "services/security";
import { WidgetData } from "services/widget";
import useWidgetService from "services/widget/useWidgetService";
import { addDays, getDayOfYear, getUTCDate, getUTCDateFormatted } from "util/date-helpers";
import { getQuerystringParams, knownQuerytringParams } from "util/querystring-helper";
import AddOrUpdateDashboardModal from "../../components/dashboard/AddOrUpdateDashboardModal";
import DashboardSelector from "./components/DashboardSelector";
import LastUpdated from "./components/LastUpdated";
import Widget from "./components/Widget";

const yearToDateTimeRangeValueDayOfYearCuttoffWowWhatAName = 91;

const DashboardView: React.FC = () => {
  const dashboardService = useDashboardService();
  const organizationService = useSLAOrganizationService();
  const copService = useSLAPortalAppService();
  const [showAddDashboardModal, setShowAddDashboardModal] = useState<{
    display: boolean;
    isFirstDashboard?: boolean;
  }>({ display: false });
  const [showCriticalModal, setShowCriticalModal] = useState<{
    display: boolean;
    title?: string;
    message?: string;
    onOk: () => void;
  }>({ display: false, onOk: noop });
  const widgetService = useWidgetService();
  const [isLoading, setIsLoading] = useState(true);
  const [widgetData, setWidgetData] = useState<WidgetData[]>();
  const [searchParams, setSearchParams] = useSearchParams();
  const pdfService = usePDFService();
  const navigate = useNavigate();
  const [pdfButtonMessage, setPdfButtonMessage] = useState<string>();
  const [currentOrganization, setCurrentOrganization] = useState<OrganizationInfo | OrganizationLineage>();
  const [canEditDashboards, setCanEditDashboards] = useState(false);
  const [allDashboards, setAllDashboards] = useState<Dashboard[]>();
  const currentTimeRange = (searchParams.get(knownQuerytringParams.timeRange) || "90") as TimeRanges;
  const [showWarningConfirmModal, setShowWarningConfirmModal] = useState<{
    display: boolean;
    title?: string;
    message?: string;
    onOk: () => void;
  }>({ display: false, onOk: noop });
  const securityService = useSLASecurityService();
  const slaOrgService = useSLAOrganizationService();

  const currentDashboard = dashboardService.dashboardStore.currentDashboard;

  async function loadDashboard(dashboardId: number, timeRange: TimeRanges) {
    setIsLoading(true);

    await widgetService.getDashboard(dashboardId, getStartAndEndDates(timeRange)).then(setWidgetData);

    setIsLoading(false);
  }

  const closeCriticalModal = () => {
    setShowCriticalModal({
      display: false,
      onOk: noop,
    });
  };

  function getStartAndEndDates(timeRange: TimeRanges) {
    const endTime = getUTCDate(addDays(new Date(), -1), "end-of-day");
    let startTime: Date;

    switch (timeRange) {
      case "30":
      case "90":
        startTime = getUTCDate(addDays(new Date(), -parseInt(timeRange)), "start-of-day");
        break;
      case "year-to-date":
        startTime = getUTCDate(new Date(endTime.getFullYear(), 0, 1), "start-of-day");
        break;
    }

    return { startTime, endTime };
  }

  useEffect(() => {
    async function onPageLoaded() {
      const organization = await organizationService.getCurrentOrganization();

      if (!organization) {
        const organizationId = organizationService.getCurrentOrganizationId();
        setShowWarningConfirmModal({
          display: true,
          title: "Cannot locate an organization",
          message: `The system is unable to find ${organizationId ? "the specified organization" : "an organization"}.`,
          onOk: goToCOP,
        });
        return;
      }

      onSelectOrganization(organization);
    }

    onPageLoaded();
    // eslint-disable-next-line
  }, []);

  function onTimeRangeChanged(newTimeRange: TimeRanges) {
    const currentQuerystringValues = getQuerystringParams(searchParams);
    setSearchParams({
      ...currentQuerystringValues,
      [knownQuerytringParams.timeRange]: newTimeRange,
    });
    loadDashboard(currentDashboard!.id, newTimeRange);
  }

  function onDashboardChanged(dashboard: Dashboard) {
    const currentQuerystringValues = getQuerystringParams(searchParams);
    setSearchParams({
      ...currentQuerystringValues,
      [knownQuerytringParams.dashboardId]: dashboard.id.toString(),
    });
    dashboardService.setCurrentDashboard(dashboard);

    loadDashboard(dashboard.id, currentTimeRange);
  }

  function onShowAddDashboardModal(isFirstDashboard?: boolean) {
    setShowAddDashboardModal({ display: true, isFirstDashboard });
  }

  async function onSelectOrganization(organization: OrganizationInfo) {
    setCurrentOrganization(organization);
    const [result, editable] = await Promise.all([
      organizationService.setCurrentOrganization(organization.id),
      securityService.userHasOrgPermission(organization.id, "editDashboards"),
    ]);
    setCanEditDashboards(editable);
    if (!result.allDashboards.length) {
      if (editable) {
        onShowAddDashboardModal(true);
      } else {
        setShowWarningConfirmModal({
          display: true,
          title: "SLA dashboard not created yet",
          message: "Only members with owner or admin roles can create a dashboard for an organization.",
          onOk: goToCOP,
        });
      }
      return;
    }
    setAllDashboards(result.allDashboards);
    const currentDashboard: Dashboard | undefined = result.currentDashboardId
      ? result.allDashboards.find((i) => i.id === result.currentDashboardId!)
      : undefined;

    if (!currentDashboard) {
      setShowWarningConfirmModal({
        display: true,
        title: "Cannot locate the dashboard",
        message: `The system is unable to find ${
          result.currentDashboardId ? "the specified dashboard" : "a dashboard"
        }.`,
        onOk: goToCOP,
      });
      return;
    }
    dashboardService.setCurrentDashboard(currentDashboard!);
    loadDashboard(currentDashboard!.id, currentTimeRange);
  }

  function onDashboardAdded(dashboard: Dashboard) {
    dashboardService.setCurrentDashboard(dashboard);
    navigate(`${SLARoutes.dashboard}/${dashboard.id}`);
  }

  async function goToCOP() {
    const copApp = await copService.getCopApp();

    if (__DEV && (!copApp || copApp.url === "#")) {
      console.error("Cannot find a URL for the 'COP' Portal app.");
      alert("DEV ONLY: Cannot find a URL for the 'COP' Portal app.");
      return;
    }
    window.location.assign(copApp.url!);

    setShowAddDashboardModal({ display: false });
  }

  let timeRangeOptions = allTimeRangeOptions;

  if (getDayOfYear(new Date()) < yearToDateTimeRangeValueDayOfYearCuttoffWowWhatAName) {
    timeRangeOptions = timeRangeOptions.filter((i) => i.value !== "year-to-date");
  }

  async function saveToPDF() {
    setPdfButtonMessage("Generating...");
    let days = currentTimeRange as TimeRanges | number;
    if (days === "year-to-date") {
      days = getDayOfYear(new Date());
    }
    const pdfBlob = await pdfService.getCurrentDashboardAsPDF();
    if (pdfBlob.type && pdfBlob.type === "application/pdf") {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = `${currentDashboard!.name}-sla-dashboard-${days}days-generated-${getUTCDateFormatted(
        new Date(),
      )}.pdf`;
      link.click();
    } else {
      setShowCriticalModal({
        display: true,
        title: "Error: Unable to generate PDF",
        onOk: closeCriticalModal,
      });
    }
    setPdfButtonMessage(undefined);
  }

  return (
    <div className="overflow-auto h-full bg-neutral-dark-5 dashboard-header-offset">
      <div className="relative h-full">
        <div className="w-5/6 mx-auto pt-6">
          <LoadableContentContainer showLoading={isLoading}>
            {currentDashboard && currentOrganization && (
              <>
                <div className="mt-2 mb-6 flex justify-between no-print">
                  <div className="flex items-center">
                    <OrganizationPicker
                      organizationService={slaOrgService}
                      currentOrganization={currentOrganization}
                      onOrganizationSelected={(org) => {
                        setIsLoading(true);
                        onSelectOrganization(org);
                      }}
                      getPickerOpenerContent={(showPicker: () => void) => (
                        <>
                          <Tooltip
                            hidden={currentOrganization.name.length <= 20}
                            variant={"dark"}
                            disableStyleInjection="core"
                            id="org-name-tooltip"
                          />
                          <Button
                            buttonType="secondary-neutral"
                            testId="select-organization"
                            className="text-lg mr-2"
                            onClick={showPicker}
                            data-tooltip-content={currentOrganization.name}
                            data-tooltip-id="org-name-tooltip"
                          >
                            <span className="whitespace-nowrap">
                              {currentOrganization.name.length <= 20
                                ? currentOrganization.name
                                : currentOrganization.name.substring(0, 18) + "..."}
                            </span>
                            <ChevronDownIcon className="ml-2" />
                          </Button>
                        </>
                      )}
                    />
                    <DashboardSelector
                      currentDashboard={currentDashboard}
                      currentOrganizationId={currentOrganization!.id}
                      dashboards={allDashboards!}
                      onAddNewDashboard={onShowAddDashboardModal}
                      onSelectDashboard={onDashboardChanged}
                    />
                    <Select
                      className="!mt-[6px]"
                      options={timeRangeOptions}
                      name="select-time-range"
                      onChange={(e) => {
                        onTimeRangeChanged(e.currentTarget.value as TimeRanges);
                      }}
                      containerClassName="!w-48 mb-0 ml-4"
                      value={currentTimeRange}
                    />
                  </div>
                  <div className="flex items-center">
                    {canEditDashboards && currentDashboard.organization.id === currentOrganization.id && (
                      <LinkButton
                        testId="link-to-dashboard"
                        to={`${SLARoutes.dashboard}/${currentDashboard!.id}`}
                        buttonClassName="mr-2"
                      >
                        <GearIcon className="mr-2" />
                        Edit
                      </LinkButton>
                    )}
                    <Button
                      testId="export-to-pdf"
                      onClick={saveToPDF}
                      disabled={!!pdfButtonMessage}
                      className="shrink-0"
                    >
                      <FilePdfIcon className="mr-2" />
                      {pdfButtonMessage || "Export to PDF"}
                    </Button>
                  </div>
                </div>
                <div className="print-only">
                  <div className="flex items-center">
                    <WexLogo />
                    <Heading
                      size="small"
                      className="pl-2 font-bold inline-block text-neutral-dark-50"
                    >
                      SLA
                    </Heading>
                  </div>
                  <div className="flex w-full justify-between pt-8 pb-6">
                    <div>
                      <Heading
                        size="x-large"
                        className="inline-block mr-4"
                      >
                        {currentDashboard!.name}
                      </Heading>
                      <Heading
                        size="medium"
                        className="inline-block"
                      >
                        {allTimeRangeOptions.find((i) => i.value === currentTimeRange)!.label}
                      </Heading>
                    </div>
                  </div>
                </div>
                {!widgetData?.length && (
                  <div className="flex flex-col justify-center items-center mt-20">
                    <EmptyLogo />
                  </div>
                )}
                {widgetData && widgetData.length > 0 && (
                  <>
                    {widgetData.map((i) => (
                      <Widget
                        key={i.widget.id}
                        widgetData={i}
                      />
                    ))}
                    <LastUpdated />
                  </>
                )}
              </>
            )}
          </LoadableContentContainer>
        </div>
      </div>
      {showCriticalModal.display && (
        <ConfirmModal
          mode="critical"
          {...showCriticalModal}
          showCancelButton={false}
          okButtonText="Close"
        ></ConfirmModal>
      )}
      {showWarningConfirmModal.display && (
        <ConfirmModal
          mode="warning"
          {...showWarningConfirmModal}
          okButtonText="Back to COP"
          onCancel={securityService.logout}
          cancelButtonText="Logout"
        >
          <p>{showWarningConfirmModal.message}</p>
        </ConfirmModal>
      )}
      {showAddDashboardModal.display && (
        <AddOrUpdateDashboardModal
          organization={currentOrganization!}
          isFirstDashboard={showAddDashboardModal.isFirstDashboard}
          onAddedOrUpdated={onDashboardAdded}
          onCancel={() =>
            showAddDashboardModal.isFirstDashboard ? goToCOP() : setShowAddDashboardModal({ display: false })
          }
        />
      )}
    </div>
  );
};

type TimeRanges = "30" | "90" | "year-to-date";

interface TimeRangeOptionValue extends OptionValueLabel {
  value: TimeRanges;
}
const allTimeRangeOptions: TimeRangeOptionValue[] = [
  {
    label: "Last 30 days",
    value: "30",
  },
  {
    label: "Last 90 days",
    value: "90",
  },
  {
    label: "Year to date",
    value: "year-to-date",
  },
];

export default DashboardView;
