import * as api from "./api";

const useTaskService = () => {
  function getLastUpdated() {
    return api.getLastUpdated();
  }

  return { getLastUpdated };
};

export default useTaskService;
