import { Heading, Label } from "@wexinc/wex-ui";
import { WidgetData } from "services/widget";
import { getFriendlyCount } from "util/display-helpers";
import { WidgetContent } from "../WidgetContentFactory";

export function getCountWidgetContent(widgetData: WidgetData): WidgetContent {
  return {
    headerContent: (
      <div className="flex items-center ">
        <Heading
          size="small"
          className="text-neutral-dark-70 font-semibold pr-2"
        >
          {getFriendlyCount(widgetData.data[0].primaryValue)}
        </Heading>
        <Label className="rounded bg-primary-10 py-0.5 px-2">API Count</Label>
      </div>
    ),
  };
}
