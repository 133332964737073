import { LoggedInUser, LoggedInUserConstructorArgs } from "@wexinc/wex-common-web";

export class SLALoggedInUser extends LoggedInUser {
  constructor(args: LoggedInUserConstructorArgs) {
    super(args);
  }
}

export type SLAPermissionIds = keyof typeof SLAPermissions;

export const SLAPermissions = {
  viewDashboards: "SLA View Dashboards",
  editDashboards: "SLA Edit Dashboards",
  editMetrics: "SLA Edit Metrics",
  shareDashboards: "SLA Share Dashboards",
};
