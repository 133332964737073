import { Widget } from "./";
import { getFetch } from "@wexinc/wex-common-web";
import { dashboardsApiRoot } from "services/dashboard/api";
import { SaveWidget, TimeRange, WidgetData } from "./models";
import { Endpoints } from "services/api";

export interface SaveWidgetArgs {
  dashboardId: number;
  widget: Widget;
}

const { get, put, doDelete } = getFetch(Endpoints.apiRoot);

// PUT /api/dashboards/{dashboardId}/widgets : create or update a widget
export function saveWidget(dashboardId: number, widget: SaveWidget): Promise<Widget> {
  return put({
    relativeRoute: `${dashboardsApiRoot}/${dashboardId}/widgets`,
    body: widget,
  });
}

// GET /api/dashboards/{dashboardId}/widgets/all
export function getAllWidgets(dashboardId: number): Promise<Widget[]> {
  return get({
    relativeRoute: `${dashboardsApiRoot}/${dashboardId}/widgets/all`,
  });
}

// DELETE /api/dashboards/{dashboardId}/widgets/{widgetId}
export function deleteWidget(dashboardId: number, widgetId: number): Promise<Response> {
  return doDelete({
    relativeRoute: `${dashboardsApiRoot}/${dashboardId}/widgets/${widgetId}`,
  });
}

// GET /api/dashboards/{dashboardId}/widgets/{widgetId}/data
export function getWidgetData(dashboardId: number, widgetId: number, timeRange: TimeRange): Promise<WidgetData> {
  return get({
    relativeRoute: `${dashboardsApiRoot}/${dashboardId}/widgets/${widgetId}/data`,
    queryParams: {
      startTime: timeRange.startTime.toISOString(),
      endTime: timeRange.endTime.toISOString(),
    },
  });
}

// GET /api/dashboards/{dashboardId}/widgets/{widgetId}/data
export function getAllWidgetData(dashboardId: number, timeRange: TimeRange): Promise<WidgetData[]> {
  return get({
    relativeRoute: `${dashboardsApiRoot}/${dashboardId}/widgets/all/data`,
    queryParams: {
      startTime: timeRange.startTime.toISOString(),
      endTime: timeRange.endTime.toISOString(),
    },
  });
}

// PUT /api/dashboards/{dashboardId}/widget-order : create or update a widget
export async function orderWidgets(dashboardId: number, widgetIds: number[]): Promise<Widget> {
  return put({
    relativeRoute: `${dashboardsApiRoot}/${dashboardId}/widget-order`,
    body: widgetIds,
  });
}
