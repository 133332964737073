module.exports = {
  presets: [require("@wexinc/wex-ui/dist/tailwind-preset.js")],
  content: [
    "./src/**/*.{ts,tsx}",
    "./public/**/*.html",
    "./node_modules/@wexinc/wex-ui/**/*.js",
    "./node_modules/@wexinc/wex-common-web/**/*.js",
  ],
  theme: {},
  plugins: [],
};
