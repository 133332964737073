import { getMetricTypeKey, Metric, MetricType } from "services/metric";
import { SaveWidget, TimeRange, Widget, WidgetData } from ".";
import * as api from "./api";
import { getMetricPreviewData } from "services/metric/api";
import { getPreviewData, propertiesForMetricType } from "util/widget-helper";

const useWidgetService = () => {
  function getWidgetsForDashboard(dashboardId: number) {
    // Back-end returns widgets sorted by displayOrder
    return api.getAllWidgets(dashboardId);
  }

  function saveWidget(dashboardId: number, widget: SaveWidget) {
    return api.saveWidget(dashboardId, widget);
  }

  function initializeNewWidget(associatedMetric: Metric): Widget {
    return {
      id: -1,
      metric: associatedMetric,
      properties: propertiesForMetricType(getMetricTypeKey(associatedMetric.type)),
      enabled: true,
      displayOrder: 0,
    };
  }

  function deleteWidget(dashboardId: number, widgetId: number) {
    return api.deleteWidget(dashboardId, widgetId);
  }

  function getDashboard(dashboardId: number, timeRange: TimeRange) {
    return api.getAllWidgetData(dashboardId, timeRange);
  }

  function orderWidgets(dashboardId: number, widgetIds: number[]) {
    return api.orderWidgets(dashboardId, widgetIds);
  }

  async function getWidgetPreview(
    organizationId: string,
    metricType: MetricType,
    dataDogId: string,
    timeRange: TimeRange,
    dataFilter?: string,
  ): Promise<WidgetData> {
    const data = await getMetricPreviewData(organizationId, metricType, dataDogId, timeRange, dataFilter);

    const metric: Metric = {
      id: -1,
      owningOrganization: {
        id: "",
        name: "",
      },
      name: "",
      type: metricType,
      dataId: dataDogId,
      privateToOrganization: false,
    };

    const widget: Widget = {
      id: -1,
      metric: metric,
      properties: propertiesForMetricType(getMetricTypeKey(metricType)),
      enabled: true,
      displayOrder: 0,
    };

    const [target, avg] = getPreviewData(data);

    if ("targetPercentage" in widget.properties) {
      widget.properties.targetPercentage = Math.round(10000 * target) / 100;
    }

    if ("targetResponseTime" in widget.properties) {
      widget.properties.targetResponseTime = Math.round(target);
    }

    const widgetData: WidgetData = {
      widget: widget,
      summary: {
        totalPercentage: metricType === MetricType.Availability ? Math.round(avg * 10000) / 100 : avg,
      },
      data: data,
    };
    return widgetData;
  }

  return {
    deleteWidget,
    getWidgetsForDashboard,
    saveWidget,
    initializeNewWidget,
    getDashboard,
    orderWidgets,
    getWidgetPreview,
  };
};

export default useWidgetService;
