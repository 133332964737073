import { ExtraErrorData } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { ApiError, GlobalErrorWrapper } from "@wexinc/wex-common-web";
import App from "app/App";
import React from "react";
import { createRoot } from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Env } from "util/environment-helper";
import "./index.css";

if (Env.sentryDSN && Env.sentryDSN.toLowerCase() !== "none") {
  Sentry.init({
    dsn: Env.sentryDSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new ExtraErrorData({ depth: 10 }),
    ],
    normalizeDepth: 11,
    tracesSampleRate: 0.5,
    environment: Env.sentryEnvironment,
    // tag error with first error code for grouping in Sentry UI
    beforeSend(event, hint) {
      const originalError = hint.originalException;
      event.tags = event.tags || {};
      if (originalError instanceof ApiError && originalError.errorCodes?.length) {
        event.tags["primary_error_code"] = originalError.errorCodes[0];
      }
      return event;
    },
    // release tag MUST BE IDENTICAL here and in script/upload-source-maps.sh
    release: `${process.env.REACT_APP_VERSION}`,
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <GlobalErrorWrapper>
      <App />
    </GlobalErrorWrapper>
  </React.StrictMode>,
);
