import { CircleInfoIcon, Heading } from "@wexinc/wex-ui";
import classnames from "classnames";
import { useMemo } from "react";
import { WidgetData } from "services/widget";
import { getWidgetContent } from "./WidgetContentFactory";

interface Props {
  widgetData: WidgetData;
  containerClassName?: string;
}

const Widget: React.FC<Props> = (props) => {
  const { widgetData, containerClassName } = props;
  const content = useMemo(
    () =>
      widgetData.data.length
        ? getWidgetContent(widgetData)
        : {
            headerContent: null,
            bodyContent: "Widget data not yet generated.",
          },
    [widgetData],
  );

  return (
    <>
      <div
        className={classnames(
          "shadow-elevation-1 rounded-lg bg-utility-white text-neutral-dark-70 p-4 mb-10",
          containerClassName,
        )}
      >
        <div className="flex justify-between">
          <Heading
            size="small"
            className="flex items-center font-semibold"
          >
            {widgetData.widget.metric.name}
            <CircleInfoIcon
              className="ml-2 fill-current cursor-pointer no-print"
              data-tip={widgetData.widget.metric.description}
            />
          </Heading>
          {content.headerContent && <div>{content.headerContent}</div>}
        </div>
        {content.bodyContent}
      </div>
    </>
  );
};

export default Widget;
