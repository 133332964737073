import * as api from "./api";
import { AddDashboard, Dashboard } from ".";
import { useContext } from "react";
import { DashboardContext } from "./DashboardProvider";

const useDashboardService = () => {
  const { dashboardStore, dispatch } = useContext(DashboardContext);

  function setCurrentDashboard(dashboard: Dashboard) {
    dispatch({ type: "set-current-dashboard", payload: dashboard });
  }

  function getDashboard(id: number) {
    return api.getDashboard(id);
  }

  function addOrUpdateDashboard(dashboard: AddDashboard | Dashboard) {
    return api.saveDashboard(dashboard);
  }

  function getAllDashboards(organizationId: string) {
    return api.getAllDashboards(organizationId);
  }

  function getSharedOrganizationsForDashboard(dashboardId: number) {
    return api.getSharedOrganizationsForDashboard(dashboardId);
  }

  function setSharedOrganizationsForDashboard(dashboardId: number, organizationIds: string[]) {
    return api.setSharedOrganizationsForDashboard(dashboardId, organizationIds);
  }

  return {
    getDashboard,
    getAllDashboards,
    getSharedOrganizationsForDashboard,
    addOrUpdateDashboard,
    setSharedOrganizationsForDashboard,
    dashboardStore,
    setCurrentDashboard,
  };
};

export default useDashboardService;
