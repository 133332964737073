import * as api from "./api";
import { useSearchParams } from "react-router-dom";
import useDashboardService from "services/dashboard";
import { getQuerystringParams, knownQuerytringParams } from "util/querystring-helper";
import { OrganizationInfo, OrganizationLineage } from "./models";
import { isEqual } from "lodash";
import { ApiError } from "@wexinc/wex-common-web";

const relevantErrorCodes = ["E-SLA-2019", "E-CMN-1041", "E-CMN-1041", "E-CMN-1009", "E-CMN-1010"];

const useSLAOrganizationService = () => {
  const dashboardSerice = useDashboardService();
  const [searchParams, setSearchParams] = useSearchParams();

  function getCurrentOrganizationId() {
    return searchParams.get(knownQuerytringParams.organizationId);
  }

  async function getCurrentOrganization(): Promise<OrganizationInfo | OrganizationLineage | null> {
    const currentOrganizationId = getCurrentOrganizationId();
    if (currentOrganizationId) {
      try {
        const lineage = await api.getOrganizationLineage(currentOrganizationId);
        return lineage;
      } catch (e) {
        if (e instanceof ApiError && e.errorCodes?.some((err) => relevantErrorCodes.includes(err))) {
          // pass through here so we can hit our current fallback logic
          return null;
        }
        throw e;
      }
    }

    const topLevelOrgs = await api.getTopLevelOrganizations();
    return topLevelOrgs.length ? topLevelOrgs[0] : null;
  }

  async function setCurrentOrganization(organizationId: string) {
    const dashboards = await dashboardSerice.getAllDashboards(organizationId);
    const firstDashboard = dashboards.length ? dashboards[0] : null;
    const currentQuerystringValues = getQuerystringParams(searchParams);
    const queryParams = {
      ...currentQuerystringValues,
      [knownQuerytringParams.organizationId]: organizationId,
    };

    let currentDashboardId = searchParams.get(knownQuerytringParams.dashboardId);
    const currentDashboardIdIsFoundInDashboards = currentDashboardId
      ? dashboards.some((i) => i.id === parseInt(currentDashboardId!))
      : false;
    if (firstDashboard && !currentDashboardIdIsFoundInDashboards) {
      currentDashboardId = firstDashboard.id.toString();
      queryParams[knownQuerytringParams.dashboardId] = currentDashboardId;
    }

    // checking equality to prevent an unnecessary add to the history stack - WSD-473
    if (!isEqual(queryParams, currentQuerystringValues)) {
      setSearchParams(queryParams);
    }

    return {
      allDashboards: dashboards,
      currentDashboardId: currentDashboardId ? parseInt(currentDashboardId) : undefined,
    };
  }

  return {
    getOrganizationLineage: api.getOrganizationLineage,
    getTopLevelOrganizations: api.getTopLevelOrganizations,
    getCurrentOrganization,
    getCurrentOrganizationId,
    setCurrentOrganization,
  };
};

export default useSLAOrganizationService;
