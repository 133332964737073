import useDashboardService, { Dashboard, AddDashboard } from "services/dashboard";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { AddOrEditModal, LoadableContentContainer, TextAreaField, TextField } from "@wexinc/wex-ui";
import { useErrorBoundary } from "react-error-boundary";
import { FieldValidationMessages, FieldValidationsError } from "@wexinc/wex-common-web";
import { OrganizationInfo } from "services/organization/models";

interface Props {
  onAddedOrUpdated: (Dashboard: Dashboard) => void;
  onCancel: () => void;
  organization: OrganizationInfo;
  existingDashboard?: Dashboard;
  isFirstDashboard?: boolean;
}

const schema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Dashboard Name is required.")
    .max(255, "Dashboard name cannot exceed 255 characters"),
  description: yup.string().trim().nullable().max(1000, "Description cannot exceed 1000 characters"),
});

const AddOrUpdateDashboardModal: React.FC<Props> = (props) => {
  const dashboardService = useDashboardService();
  const [customValidationMessages, setCustomValidationMessages] = useState<FieldValidationMessages>();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const { showBoundary } = useErrorBoundary();
  const { existingDashboard, organization, onAddedOrUpdated, isFirstDashboard, ...rest } = props;

  const defaultValues: AddDashboard = existingDashboard
    ? { ...existingDashboard }
    : {
        organization: organization,
        name: isFirstDashboard ? "Default dashboard" : "",
      };

  async function onSubmit(dashboard: Dashboard | AddDashboard) {
    setIsBusy(true);
    setCustomValidationMessages(undefined);
    try {
      const addedOrUpdatedDashboard = await dashboardService.addOrUpdateDashboard(dashboard);
      onAddedOrUpdated(addedOrUpdatedDashboard);
    } catch (e) {
      setIsBusy(false);
      if (e instanceof FieldValidationsError) {
        setCustomValidationMessages(e.fieldValidationMessages);
      } else {
        showBoundary(e);
      }
    }
  }

  return (
    <AddOrEditModal
      formProps={{
        onSubmit,
        options: {
          defaultValues,

          resolver: yupResolver(schema),
        },
      }}
      {...rest}
      titleOrHeader={existingDashboard ? `Edit ${existingDashboard.name}` : "New Dashboard"}
    >
      <LoadableContentContainer showLoading={isBusy} />

      <div className="w-[340px]">
        {isFirstDashboard && (
          <p className="mb-4">
            It looks like this organization doesn’t have an SLA dashboard yet. Would you like to create one now?
            Clicking Cancel will send you to COP.
          </p>
        )}
        <TextField
          readonly
          name="organization.name"
          label="Parent organization"
        />
        <TextField
          name="name"
          label="Name"
          showRequiredDot
          externallySuppliedValidationMessage={customValidationMessages?.["name"]}
        />
        <TextAreaField
          name="description"
          label="Description"
        />
      </div>
    </AddOrEditModal>
  );
};

export default AddOrUpdateDashboardModal;
