import { NotFound } from "@wexinc/wex-common-web";
import AppHeader from "app/AppHeader";
import { SLAAdminRoutes, SLARoutes } from "app/routes";
import AdminLayout from "pages/Administration/Dashboard/AdminLayout";
import MetricLibrary from "pages/Administration/Dashboard/MetricLibrary";
import Widgets from "pages/Administration/Dashboard/Widgets";
import { LoginCallback } from "@okta/okta-react";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { DashboardProvider } from "services/dashboard/DashboardProvider";
import useSLASecurityService from "services/security";
import DashboardView from "../pages/Dashboard/DashboardView";
import AppBootstrapper from "./AppBootstrapper";

function ApplicationRoutes() {
  // a functional component used to define the routes for the application
  const slaSecurityService = useSLASecurityService();
  const nodeRef = React.useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (slaSecurityService.securityStore.authStatus === "not-authenticated") {
      slaSecurityService.gotoLogin();
    }
    // eslint-disable-next-line
  }, [location, slaSecurityService.securityStore]);

  return (
    <>
      <Routes>
        <Route
          path={SLARoutes.loginCallback}
          element={<LoginCallback />}
        />
        <Route
          path="*"
          element={null}
        />
      </Routes>
      {slaSecurityService.securityStore.authStatus === "authenticated" && (
        <DashboardProvider>
          <AppBootstrapper>
            <div className="h-screen text-neutral-dark-100 overflow-hidden">
              <AppHeader />
              <div className="h-full relative">
                <TransitionGroup component={null}>
                  <CSSTransition
                    nodeRef={nodeRef}
                    key={location.pathname}
                    classNames="fade"
                    timeout={1000}
                  >
                    <div
                      ref={nodeRef}
                      className="h-full w-full"
                      style={{
                        position: "relative",
                      }}
                    >
                      <Routes location={location}>
                        <Route
                          path={SLARoutes.root}
                          element={<DashboardView />}
                        />
                        <Route
                          path={SLARoutes.dashboard}
                          element={<AdminLayout />}
                        >
                          <Route
                            path={SLAAdminRoutes.root}
                            element={<Widgets />}
                          />
                          <Route
                            path={`${SLAAdminRoutes.root}/${SLAAdminRoutes.metricLibrary}`}
                            element={<MetricLibrary />}
                          />
                        </Route>
                        <Route
                          path="*"
                          element={<NotFound rootRoute={SLARoutes.root} />}
                        />
                      </Routes>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </div>
            <Tooltip
              className="z-10 max-w-xs"
              disableStyleInjection="core"
            />
          </AppBootstrapper>
        </DashboardProvider>
      )}
    </>
  );
}

export default ApplicationRoutes;
