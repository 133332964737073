import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useDashboardService from "services/dashboard";
import useSLAPortalAppService from "services/portal-app/useSLAPortalAppService";
import useSLASecurityService from "services/security";
import { SLARoutes } from "app/routes";
import { knownQuerytringParams } from "util/querystring-helper";
import useSLAOrganizationService from "services/organization";
import { Header, PortalApp } from "@wexinc/wex-common-web";

const AppHeader = () => {
  const navigate = useNavigate();
  const slaSecurityService = useSLASecurityService();
  const dashboardService = useDashboardService();
  const organizationService = useSLAOrganizationService();
  const [portalApps, setPortalApps] = useState<PortalApp[]>();
  const portalAppService = useSLAPortalAppService();
  let dashboardId: number | null = null;
  let organizationId: string | null = null;

  const currentDashboard = dashboardService.dashboardStore.currentDashboard;

  if (currentDashboard) {
    dashboardId = currentDashboard.id;
    organizationId = currentDashboard.organization.id;
  } else if (organizationService.getCurrentOrganizationId()) {
    organizationId = organizationService.getCurrentOrganizationId();
  }

  const homeUrl = new URL(SLARoutes.root, window.location.origin);
  if (organizationId) {
    homeUrl.searchParams.set(knownQuerytringParams.organizationId, organizationId);
  }
  if (dashboardId) {
    homeUrl.searchParams.set(knownQuerytringParams.dashboardId, dashboardId.toString());
  }

  useEffect(() => {
    portalAppService.getPortalApps().then(setPortalApps);
    // eslint-disable-next-line
  }, []);

  return (
    <Header
      appDisplayName="SLA"
      portalApps={portalApps}
      logout={() => slaSecurityService.logout()}
      onAppHomeClick={() => navigate(homeUrl.pathname + homeUrl.search)}
      loggedInUser={slaSecurityService.securityStore.loggedInUser}
    ></Header>
  );
};

export default AppHeader;
