import { Button, ChevronDownIcon, ChevronUpIcon, CloseFunctionType, PlusIcon, Popover } from "@wexinc/wex-ui";
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { Dashboard } from "services/dashboard";
import useSLASecurityService from "services/security";

interface Props {
  currentDashboard: Dashboard;
  currentOrganizationId: string;
  dashboards: Dashboard[];
  onAddNewDashboard: () => void;
  onSelectDashboard: (dashboard: Dashboard) => void;
}

const arrowClassnames = "ml-2 h-4 w-4 fill-current";

const DashboardSelector: React.FC<Props> = (props) => {
  const securityService = useSLASecurityService();
  const { onAddNewDashboard, onSelectDashboard, dashboards, currentDashboard, currentOrganizationId } = props;

  const [canEditDashboards, setCanEditDashboards] = useState(false);
  useEffect(() => {
    securityService.userHasOrgPermission(currentOrganizationId, "editDashboards").then(setCanEditDashboards);
  }, [currentOrganizationId]);

  const allButCurrentDashboard = (currentDashboard: Dashboard) => {
    const filtered = (dashboards || [])
      .filter((i) => i.id !== currentDashboard!.id)
      .map((i) => {
        const clone = { ...i };
        return formatDashboardName(clone);
      });
    return filtered.sort((a: Dashboard, b: Dashboard) => {
      return a.name.localeCompare(b.name);
    });
  };
  const clonedCurrentDashboard = formatDashboardName({ ...currentDashboard });

  function formatDashboardName(dashboard: Dashboard) {
    if (dashboard.organization.id !== currentOrganizationId) {
      dashboard.name += ` (${dashboard.organization.name})`;
    }
    return dashboard;
  }

  const [visibleDashboards, setVisibleDashboards] = useState<Dashboard[]>([]);

  useEffect(() => {
    setVisibleDashboards(allButCurrentDashboard(currentDashboard!));
    // eslint-disable-next-line
  }, [currentDashboard]);

  function onReopen() {
    setTimeout(() => setVisibleDashboards(allButCurrentDashboard(currentDashboard!)), 0);
  }

  return (
    <Popover
      testId="show-dashboard-selector"
      className="min-w-[230px] p-4 h-64"
      onReopen={onReopen}
      getAnchorContent={(isOpen) => (
        <div
          data-test-id="dashboard-menu-button"
          className="flex py-[5px] px-4 bg-utility-white items-center text-neutral-dark-100 rounded-lg border border-neutral-dark-30"
        >
          <span
            data-data-tooltip-id="dashboard-name-tooltip"
            className="whitespace-nowrap"
            data-data-tooltip-content={clonedCurrentDashboard?.name}
          >
            {clonedCurrentDashboard?.name.length <= 20
              ? clonedCurrentDashboard?.name
              : clonedCurrentDashboard?.name.substring(0, 18) + "..."}
          </span>

          {isOpen ? <ChevronUpIcon className={arrowClassnames} /> : <ChevronDownIcon className={arrowClassnames} />}
          <Tooltip
            hidden={clonedCurrentDashboard?.name.length <= 20}
            variant={"dark"}
            id="dashboard-name-tooltip"
            disableStyleInjection="core"
          />
        </div>
      )}
    >
      {(close: CloseFunctionType) => (
        <>
          {canEditDashboards && (
            <Button
              testId="add-new-company"
              buttonType="tertiary"
              className="font-normal w-full flex !justify-start"
              doPadXAxis={false}
              onClick={() => {
                onAddNewDashboard();
                close();
              }}
            >
              <PlusIcon className="mr-2" /> New dashboard
            </Button>
          )}
          <ul>
            {visibleDashboards.map((i) => (
              <li key={i.id}>
                <Button
                  testId="select-company"
                  buttonType="secondary-neutral"
                  className="font-normal flex flex-col items-baseline w-full"
                  onClick={() => {
                    onSelectDashboard(i);
                    close();
                  }}
                >
                  {i.name}
                </Button>
              </li>
            ))}
          </ul>
        </>
      )}
    </Popover>
  );
};

export default DashboardSelector;
