import { MetricTypeKeys } from "services/metric";
import {
  AvailabilityWidgetProperties,
  CountWidgetProperties,
  PerformanceWidgetProperties,
  WidgetDataItem,
} from "services/widget";

// Simple factory method for now. Later we could get fancier if-needed.
export function propertiesForMetricType(type: MetricTypeKeys) {
  switch (type) {
    case "Availability": {
      const availabillityWidgetProps: AvailabilityWidgetProperties = {
        targetPercentage: undefined,
        includeMaintenance: false,
      };
      return availabillityWidgetProps;
    }
    case "Performance": {
      const performanceWidgetProps: PerformanceWidgetProperties = {
        targetResponseTime: undefined,
      };
      return performanceWidgetProps;
    }
    case "Count": {
      const countWidgetProps: CountWidgetProperties = {};
      return countWidgetProps;
    }
  }
}

export function getPreviewData(data: WidgetDataItem[]) {
  let sum = 0;
  const sortedData = data
    .map((item) => {
      if (item.secondaryValue && item.primaryValue) {
        sum += item.primaryValue / item.secondaryValue;
        return item.primaryValue / item.secondaryValue;
      }
      if (item.primaryValue && !item.secondaryValue) {
        sum += item.primaryValue;
        return item.primaryValue;
      }
      return 0;
    })
    .sort((a, b) => b - a);

  const target = percentile(sortedData, 0.9);
  const avg = sum / data.length;

  return [target, avg];
}

export function percentile(arr: number[], p: number) {
  if (arr.length === 0) return 0;
  if (p <= 0) return arr[0];
  if (p >= 1) return arr[arr.length - 1];

  const index = (arr.length - 1) * p,
    lower = Math.floor(index),
    upper = lower + 1,
    weight = index % 1;

  if (upper >= arr.length) return arr[lower];
  return arr[lower] * (1 - weight) + arr[upper] * weight;
}
